import BaseInteraction from './BaseInteraction';
import AlignmentHelper from '@/Helpers/Alignment';

// consts
import { ROTATION_HANDLES, RESIZE_HANDLES } from '@/Constants/UI';

// actions
import TranslateGroupsAction from '@/Actions/TranslateGroups';
import ResizeGroupsAction from '@/Actions/ResizeGroups';
import RotateGroupsAction from '@/Actions/RotateGroups';
import ResolveGroupSelectionAction from '@/Actions/ResolveGroupSelection';
import SetCursorAction from '@/Actions/SetCursor';
import SetSelectionAction from '@/Actions/SetSelection';
import LineToolAction from '@/Actions/LineTool';

// selectors
import { selectSelectedGroupIds } from '@/Redux/Slices/SelectionSlice';
import { selectCanvas, selectSvgGroupSet } from '@/Redux/Slices/CanvasSlice';
import {
  selectViewport,
  selectNonScalingPixelFactor,
  selectScreenToCanvas,
} from '@/Redux/Slices/ViewportSlice';
import { selectOptions } from '@/Redux/Slices/SherpaContainerSlice';
import { selectSelectedLine } from '@/Redux/Slices/LineToolSlice';

// utils
import { sortSelectionByArea } from '@/Utility/shapes';
import UIState from '@/UILayer/State/UIState';
import { selectIsInsertPointMode } from '@/Redux/Slices/UISlice';
import { Shape } from '@shapertools/sherpa-svg-generator/SvgGroup';

// temp
const MINIMUM_POINT_INTERACTION_DISTANCE = 15;

// handles keeping track of CSS updates for hover states
// this approach is not ideal, but will make for a more
// reliable hover experience
// const hoverStateHelper = new GlobalCssHelper('default-ui-hover-state-helper');

export default class TransformGroupsInteraction extends BaseInteraction {
  interactionId = 'Transform Groups';

  ignoreWhen() {
    // cannot transform groups in insert point mode
    return this.useSelector(selectIsInsertPointMode);
  }

  createAlignmentHelper(options) {
    const { useSelector } = this;
    const viewport = useSelector(selectViewport);
    const canvas = useSelector(selectCanvas);
    const svgGroupSet = useSelector(selectSvgGroupSet);
    const ids = options.selectedIds || useSelector(selectSelectedGroupIds);
    const ignoreGroupIds = ids;
    const selectedGroups = ids.map((id) =>
      svgGroupSet.find((item) => item.id === id)
    );
    return new AlignmentHelper(canvas, viewport, {
      ...options,
      selectedGroups,
      ignoreGroupIds,
    });
  }

  onMouseMove(event) {
    const { useSelector } = this;

    // don't update the cursor if currently in an action
    if (this.action) {
      return;
    }

    // track if we're hovering a line
    const lineTool = this.createAction(LineToolAction);
    const over = this.getGroupsAt({ ...event.center, hitDetectEdge: true });
    const ids = over.map(({ groupId: id }) => id);

    // check if this should show the line selection
    const isOverLine = lineTool.isTwoAdjacentPoints(ids);
    UIState.update({
      hoverLine: isOverLine ? ids : null,
    }).apply();

    // update for hovers
    const selection = useSelector(selectSelectedGroupIds);
    const isTouch = !!event.sourceCapabilities?.firesTouchEvents;
    this.refreshHoverState(event.center, { isTouch });

    // don't do this if there's no selection
    if (!selection.length) {
      return;
    }

    // check the anchor
    const cursor = this.createAction(SetCursorAction);

    // if over the box, no handles to show
    const overSelectionBox = this.hitTestSelectionBox(event.center);
    if (overSelectionBox) {
      this.displayedHandle = null;
      return cursor.toDefault();
    }

    // find the handle
    const handle = this.getHandleAt(event.center);
    const ignoreHandle = this.isHandleSameAsAnchor(handle);

    // if there's a handle, update the cursor
    if (!ignoreHandle && ROTATION_HANDLES.includes(handle)) {
      cursor.toRotate(handle);
    } else if (!ignoreHandle && RESIZE_HANDLES.includes(handle)) {
      cursor.toResize(handle);
    }
    // reset the handle to default
    else {
      this.displayedHandle = null;
      return cursor.toDefault();
    }

    // save the handle
    this.displayedHandle = handle;
  }

  onPointerDown(event) {
    this.lastKnownHandle = this.displayedHandle;

    if (this.lastKnownHandle) {
      this.setActive();
    }

    // is a starting over the selection box (no handles considered)
    this.startedOverSelectionBox = this.hitTestSelectionBox(event.center);

    // handle touch events and get a larger handle
    if (event.isTouch) {
      this.mobileHandle = this.getHandleAt(event.center, {
        extendSelection: true,
      });
    }
    this.pointerMoved = false;
  }

  onPointerUp(event, manager) {
    const { createAction } = this;
    const selectionAction = createAction(SetSelectionAction);
    const lineTool = createAction(LineToolAction);

    // if for some reason there's an action in progress (which shouldn't happen
    // just skip resolving the selection)
    const { isDragging } = manager;
    if (!this.action && !this.pointerMoved && !isDragging) {
      this.origin = event;

      // check what the pointer is over
      const svgGroupSet = this.useSelector(selectSvgGroupSet);
      let over = this.getGroupsAt({
        ...event.center,
        ...{
          hitDetectEdge: true,
          hitDetectFill: true,
        },
      });

      over = sortSelectionByArea(over, svgGroupSet);

      // check if this click happened to be points that are connected
      // which means that it's most likely a line -- this shouldn't
      // happen unless it's two adjacent points in a shape but we might
      // consider adding another check for that here
      const didSelectLine = lineTool.isTwoAdjacentPoints(over);
      if (didSelectLine) {
        selectionAction.setSelectedLine(didSelectLine ? over : null);
        manager.preventRemaining();
      }

      // check if over the existing selection box
      const overSelectionBox = this.hitTestSelectionBox(event.center);

      // they're the same so it's just a click so void click on the canvas
      if (!overSelectionBox && !over.length) {
        this.refreshMenuState({ reason: 'void-click' });
        this.clearHoverState();
        this.clearSelection();

        // manager.preventRemaining();
        return true;
      }

      // determine what to do with the selection
      const selection = this.createAction(ResolveGroupSelectionAction);
      const appendToSelection = this.shouldAppendToSelection(event);
      this.selection = selection.resolve(over, {
        appendToSelection,
        didSelectLine,
      });

      // updates the menu state
      this.refreshMenuState({
        reason: 'selection',
        selection: this.selection,
      });

      // selection cleared
      if (!this.selection.length) {
        this.clearSelection();
      }

      // update the hover state
      // wait a moment for the state to update
      // TODO: ideally, we just pass the new selection state into this function
      setTimeout(() =>
        this.refreshHoverState(event.center, { isTouch: event.isTouch })
      );
    }
    if (isDragging) {
      manager.isDragging = false;
    }
  }

  // checks for events that should append the targeted layer
  // onto the current selection
  shouldAppendToSelection(event) {
    return event.shiftKey || /touch/i.test(event.pointerType);
  }

  onPointerMoveStart(event, manager) {
    this.pointerMoved = true;

    const { useSelector } = this;
    let overSelectionBox = this.startedOverSelectionBox; // || this.hitTestSelectionBox(event.center);
    let manipulateSinglePoint;

    const screenToCanvas = useSelector(selectScreenToCanvas);
    let selectedLine = useSelector(selectSelectedLine);
    const selectedIds = useSelector(selectSelectedGroupIds);
    const svgGroupSet = useSelector(selectSvgGroupSet);
    const lineTool = this.createAction(LineToolAction);
    const selectionAction = this.createAction(SetSelectionAction);
    const selectionType = this.getSelectionType();
    const nspf = useSelector(selectNonScalingPixelFactor);

    // check the handle
    let handle;
    if (!this.startedOverSelectionBox) {
      handle =
        this.mobileHandle ||
        this.lastKnownHandle ||
        this.displayedHandle ||
        this.getHandleAt(event.center, {
          extendSelection: event.isTouch,
        });
      if (handle === 'centroid') {
        handle = null;
      }
    }

    // check if ignoring resize behavior
    const ignoreHandle = this.isHandleSameAsAnchor(handle);

    // use the current selection
    this.selection = [...selectedIds];

    // not over the selection box, try and resolve the selection again
    if (!overSelectionBox && !handle) {
      // for touch events, don't try and grab new groups
      if (event.isTouch) {
        return;
      }

      let over = this.getGroupsAt({
        ...event.center,
        ...{
          hitDetectEdge: true,
          hitDetectFill: true,
          multiSelection: true,
        },
      });

      // expand out all shapes
      for (let i = over.length; i-- > 0; ) {
        const selection = over[i];
        const group = this.getGroupById(selection.groupId);
        if (group.tool?.type === Shape.SHAPE) {
          over.splice(i, 1);

          // if it's closed, it can be selected, but we need to
          // replace the selection with just points
          if (group.tool.params.closed) {
            over.push(
              ...group.tool.params.points.map((id) => ({
                pathId: 'none',
                groupId: id,
              }))
            );
          }
        }
      }

      let updateSelection = true;

      // check if a line was selected
      const overIds = over.map(({ groupId: id }) => id);
      const didSelectLine = lineTool.isTwoAdjacentPoints(overIds);

      // check if it matches the current selected line
      const hasPointA = overIds.includes(selectedLine?.[0]);
      const hasPointB = overIds.includes(selectedLine?.[1]);
      const isSameSelectedLine = hasPointA && hasPointB;
      const isPartOfSelectedLine = hasPointA || hasPointB;

      // no line is selected
      if (!isPartOfSelectedLine && !didSelectLine) {
        selectionAction.setSelectedLine(null);
        selectedLine = null;
        this.selection = [];
      }

      // check if this click happened to be points that are connected
      // which means that it's most likely a line -- this shouldn't
      // happen unless it's two adjacent points in a shape but we might
      // consider adding another check for that here
      if (!selectedLine || (didSelectLine && !isSameSelectedLine)) {
        const select = this.createAction(SetSelectionAction);
        const appendToSelection = this.shouldAppendToSelection(event);

        // set the selection
        this.selection = appendToSelection
          ? [...(this.selection || []), ...overIds]
          : [...overIds];

        // update the selection
        const selected = this.selection.map((id) => ({
          groupId: id,
          pathId: 'none',
        }));
        select.set(selected);

        // only allow line selection when it's the only line
        selectionAction.setSelectedLine(
          didSelectLine && this.selection.length === 2 ? overIds : null
        );

        updateSelection = false;
      }

      // if this has a line selected and the points
      // this is over already match
      if (selectedLine) {
        // if the cursor is near either of the
        // points on the line, then prevent the update and
        // allow for just a single point to be transformed
        // while maintaining selection
        const origin = screenToCanvas(event.center.x, event.center.y);
        const groups = over.map(({ groupId }) =>
          svgGroupSet.find(({ id: otherId }) => otherId === groupId)
        );
        const nearest = getNearestToPointer(origin, groups);
        if (nearest?.distance < MINIMUM_POINT_INTERACTION_DISTANCE * nspf) {
          updateSelection = false;
          manipulateSinglePoint = nearest.id;
        }
      }

      if (updateSelection) {
        // if selecting a shape, select all points instead
        // TODO: this happens often enough it should be a utility method somewhere
        for (let i = over.length; i-- > 0; ) {
          const group = this.getGroupById(over[i].groupId);
          if (group.tool.type === Shape.SHAPE) {
            over.splice(i, 1);
            over = over.concat(
              group.tool.params.points.map((groupId) => ({
                pathId: 'none',
                groupId,
              }))
            );
          }
        }

        // sort by area -- in this selection, the path ID is unimportant and can be ignored
        over = sortSelectionByArea(over, svgGroupSet, false)
          .map((group) => {
            return group.basePathSet.map((bps) => ({
              groupId: group.id,
              pathId: bps.id,
            }));
          })
          .flat();

        const overGroupIds = over.map((o) => o.groupId);
        const hasIdsInCommon = over.some((item) => overGroupIds.includes(item));
        //completely different groups are selected now...we should update the selection to reflect that
        if (!hasIdsInCommon) {
          const select = this.createAction(SetSelectionAction);
          select.set(over);
          this.selection = overGroupIds;
        }
        //otherwise, resolve the selection!
        else {
          const selection = this.createAction(ResolveGroupSelectionAction);
          const appendToSelection = this.shouldAppendToSelection(event);

          this.selection = selection.resolve(over, { appendToSelection });
        }
      }
    }

    // make sure there's something to transform
    const hasSelection = !!this.selection?.length;
    if (!hasSelection) {
      return;
    }

    const selectable = selectionType ? selectionType?.selectability : true;

    // check what actions are allowed
    const allowTranslate = selectable && hasSelection;
    const allowRotate =
      selectable &&
      !overSelectionBox &&
      RotateGroupsAction.isAllowedHandle(handle);
    const allowResize =
      selectable &&
      !overSelectionBox &&
      ResizeGroupsAction.isAllowedHandle(handle);

    // resizing groups
    if (allowResize && !ignoreHandle) {
      const groups = this.getGroupsFromIds(this.selection);
      const alignmentHelper = this.createAlignmentHelper({
        isResize: true,
        handle,
      });
      this.action = this.createAction(ResizeGroupsAction, groups, handle, {
        alignmentHelper,
      });
    }
    // rotating groups
    else if (allowRotate && !ignoreHandle) {
      const groups = this.getGroupsFromIds(this.selection);
      this.action = this.createAction(
        RotateGroupsAction,
        groups,
        event.center,
        handle
      );
    }
    // translating groups
    else if (allowTranslate) {
      const alignmentHelper = this.createAlignmentHelper({});

      // when just points
      let selection = [...this.selection];

      // is an unusual transform
      if (selectedLine && manipulateSinglePoint) {
        selection = [manipulateSinglePoint];
      }

      this.action = this.createAction(TranslateGroupsAction, selection, {
        alignmentHelper,
      });
    }
    // just ignore this
    else {
      return;
    }

    // also make this active
    this.setActive();
    // manager.activeInteraction = 'TransformGroups';
  }

  onActivePointerMove(event) {
    const resizeFromCenter = event.altKey;
    let {
      alignToGrid: snapToGrid,
      useSnapping: snapToObjects,
      usePositioning,
    } = this.useSelector(selectOptions);

    // override key
    if (event.ctrlKey) {
      snapToObjects = snapToGrid = false;
    }

    if (!usePositioning) {
      snapToGrid = false;
    }

    const deltaX = event.movement[0];
    const deltaY = event.movement[1];

    if (this.action instanceof TranslateGroupsAction) {
      this.action.translateBy(deltaX, deltaY, {
        snapToGrid,
        snapToObjects,
      });
    } else if (this.action instanceof ResizeGroupsAction) {
      this.action.resizeBy(deltaX, deltaY, {
        snapToGrid,
        snapToObjects,
        resizeFromCenter,
      });
    } else if (this.action instanceof RotateGroupsAction) {
      this.action.rotateBy(event.center, snapToObjects);
    }
  }

  async onActivePointerMoveEnd(_, manager) {
    // resolvable actions
    if (
      this.action instanceof TranslateGroupsAction ||
      this.action instanceof ResizeGroupsAction ||
      this.action instanceof RotateGroupsAction
    ) {
      this.action.resolve();

      // update the selection box
      const selection = this.createAction(SetSelectionAction);
      await selection.refresh();

      // update selection logic
      this.refreshMenuState({
        currentSelection: this.selection,
        newSelection: selection,
      });
    }

    delete this.action;
    this.setActive(false);
    manager.activeInteraction = null;
  }
}

function getNearestToPointer({ x, y }, groups) {
  if (!groups.length) {
    return null;
  }

  const [nearest] = groups
    .map((group) => {
      const distance = Math.hypot(group.position.x - x, group.position.y - y);
      return { distance, group };
    })
    .sort((a, b) => a.distance - b.distance);

  return {
    distance: nearest.distance,
    id: nearest.group.id,
  };
}
