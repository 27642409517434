import React, { ReactNode } from 'react';

//selector
import { useSelector } from 'react-redux';
import { selectModal } from '@/Redux/Slices/SherpaContainerSlice';

//actions
import ModalActions from '../../Actions/Modal';
import { useAction } from '../../Actions/useAction';
import { MODAL_TYPES } from '../../defaults';

type ChildrenProps = {
  children: ReactNode;
};

export function Content(props: ChildrenProps) {
  return <div className='modal--content--left--content'>{props.children}</div>;
}

export function Footer(props: ChildrenProps) {
  return <div className='modal--content--left--footer'>{props.children}</div>;
}

type PanelProps = ChildrenProps & {
  position: 'left' | 'right' | 'center';
};

export function Panel(props: PanelProps) {
  return (
    <div className={`modal--content--${props.position ?? 'left'}`}>
      {props.children}
    </div>
  );
}

type ButtonProps = ChildrenProps & {
  onClick?: () => void;
  className?: string;
  label?: String;
};

export function Button(props: ButtonProps) {
  function onClick() {
    if (props.onClick) {
      props.onClick();
    }
  }

  return (
    <div
      className={`modal--content--button ${props.className || ''}`}
      onClick={onClick}
    >
      {props.children || props.label}
    </div>
  );
}

export function Title(props: ChildrenProps) {
  return (
    <div className='modal--content--left--content--title'>{props.children}</div>
  );
}

type ModalProps = ChildrenProps & {
  clearURL?: boolean;
  className?: string;
  dataCy?: string;
};

export default function Modal(props: ModalProps) {
  const modalActions = useAction(ModalActions);
  const modalType = useSelector(selectModal);

  const close = () => {
    if (modalType !== MODAL_TYPES.SIGNIN_MODAL) {
      modalActions.closeModal(props.clearURL);
    }
  };

  return (
    <div className={`modal ${props.className}`} data-cy={props.dataCy}>
      <div className='modal--content'>
        <div className={props.className}>{props.children}</div>
      </div>
      <div className='modal--overlay' onClick={close} />
    </div>
  );
}

Modal.Content = Content;
Modal.Footer = Footer;
Modal.Panel = Panel;
Modal.Title = Title;
Modal.Button = Button;
