import React, { MouseEvent } from 'react';
import Icon from '@/Styles/Icons/Icon';

type Props<T> = {
  children?: React.ReactNode;
  className?: string;
  dataCy?: string;

  // TODO: replace this with the proper icon type once Icon is in TypeScript
  icon?: string;

  value: T;
  onClick: (value: T, event: MouseEvent) => void;
};

export default function Button<T>(props: Props<T>) {
  // handles the click event
  function onClick(event: MouseEvent) {
    props.onClick(props.value, event);
  }

  return (
    <div
      className={`properties-panel--button ${props.className || ''}`}
      data-cy={props.dataCy}
      onClick={onClick}
    >
      {props.icon && <Icon icon={props.icon} />}
      {props.children}
    </div>
  );
}
