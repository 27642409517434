import { getId, transform } from '../BasePathOps';
import { getSvgPathCssClass } from '../CutParamsOps';
import {
  BasePath,
  generatePathId,
} from '@shapertools/sherpa-svg-generator/BasePath';
import {
  createMatrix33,
  getSVGTransformParams,
  multiplyMatrix33,
} from '@shapertools/sherpa-svg-generator/Matrix33';
import {
  createSvgPathWithCSSAndAttributes,
  createSvgPathWithCSSClasses,
  PathAttribute,
} from '@shapertools/sherpa-svg-generator/SvgGenerator';
import { Shape, SvgGroup } from '@shapertools/sherpa-svg-generator/SvgGroup';

export const createScaledBasePathSvg = (
  svgGroupId: string,
  basePathId: string,
  scaledBasePath: BasePath,
  hasTessellationFeatureFlag: boolean,
  classNames?: string[],
  attrs?: PathAttribute[]
) => {
  const basePathSvgId = generatePathId(svgGroupId, basePathId, 'basePath');

  const baseCSS =
    classNames !== undefined ? classNames : getSvgPathCssClass('base');
  const scaledBasePathSvg = hasTessellationFeatureFlag
    ? createSvgPathWithCSSAndAttributes(
        scaledBasePath,
        basePathSvgId,
        baseCSS,
        attrs || [],
        true
      )
    : createSvgPathWithCSSClasses(scaledBasePath, basePathSvgId, baseCSS);

  return scaledBasePathSvg;
};

export const getScaledBasePathCache = (
  basePath: BasePath,
  svgGroup: SvgGroup,
  extraArgs: {
    useSourceSvg: boolean;
    hasTessellationFeatureFlag: boolean;
  } = {
    useSourceSvg: true,
    hasTessellationFeatureFlag: false,
  }
): {
  pathData: BasePath[];
  pathSvg: string;
} => {
  const { useSourceSvg, hasTessellationFeatureFlag } = extraArgs;
  const basePathId = getId(basePath) as string;
  if (!hasTessellationFeatureFlag) {
    if (svgGroup?.postProcessPathSet?.length > 0) {
      return {
        pathData: [basePath],
        pathSvg: createScaledBasePathSvg(
          svgGroup.id,
          basePathId,
          basePath,
          false
        ),
      };
    }

    const scaledPathData = transform(basePath, svgGroup.stretchMtx) as BasePath;
    const pathSvg = createScaledBasePathSvg(
      svgGroup.id,
      basePathId,
      scaledPathData,
      false
    );
    return { pathData: [scaledPathData], pathSvg };
  }

  if (
    useSourceSvg &&
    (svgGroup.tool.type !== Shape.SHAPESHIFTER ||
      svgGroup.postProcessPathSet.length > 0)
  ) {
    const transformMtx =
      typeof basePath.sourceSvg === 'object' &&
      'sourceTransform' in basePath.sourceSvg
        ? basePath.sourceSvg?.sourceTransform
        : createMatrix33();

    const stretchMtx = svgGroup.stretchMtx;
    const allTransformsMtx = multiplyMatrix33(stretchMtx, transformMtx);

    const transformAttrs = [
      {
        name: 'transform',
        value: getSVGTransformParams(allTransformsMtx),
      },
    ];

    return {
      pathData: [basePath],
      pathSvg: createScaledBasePathSvg(
        svgGroup.id,
        basePathId,
        basePath,
        true,
        getSvgPathCssClass('base'),
        transformAttrs
      ),
    };
  }

  const scaledPathData = transform(basePath, svgGroup.stretchMtx);
  const pathSvg = createScaledBasePathSvg(
    svgGroup.id,
    basePathId,
    scaledPathData,
    true
  );
  return {
    pathData: [scaledPathData],
    pathSvg,
  };
};
