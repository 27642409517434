import React from 'react';
import Section from './Components/Section';
import SectionOption from './Components/SectionOption';
import Icon from '@/Styles/Icons/Icon';

type Props = {
  children?: React.ReactNode;

  menuName?: string;
  // TODO use icon string union once Icon is TypeScript
  icon?: string;
};
export default function ActionMenuList(props: Props) {
  const { menuName, icon } = props;
  return (
    <div className='action-list-menu'>
      {menuName && (
        <div className='action-list-menu--title'>
          {icon && <Icon icon={icon} />}
          {menuName}
        </div>
      )}
      {props.children}
    </div>
  );
}

ActionMenuList.Section = Section;
ActionMenuList.SectionOption = SectionOption;
