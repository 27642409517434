import { useSelector } from 'react-redux';

// selectors
import { selectReviewModeLoading } from '@/Redux/Slices/UISlice';

// components
import Viewport from '@/UILayer/Components/Viewport';
import Background from './Components/Background';
import Loading from '@/Components/Loading/Loading';
import UIState from '@/UILayer/State/UIState';
import SvgGroup from '@/UILayer/State/Helpers/SvgGroup';
import ReviewModeGroup from '@/UILayer/Components/ReviewModeGroup';

const DEPTH_LEVELS = [0, 0.0254, 3.175, 6.35, 9.525, 12.7, 25.4, 38.1];

function getLowest<T>(find: T, values: T[]): number {
  let bestMatch = 0;

  for (let i = 1; i < values.length; i++) {
    if (values[i] > find) {
      break;
    }

    bestMatch = i;
  }

  return bestMatch + 1;
}

type Props = {
  ui: UIState;
};

export default function ReviewUI(props: Props) {
  const { ui } = props;
  function extendGroup(group: SvgGroup) {
    const { cutDepth } = group;
    const depthLevel = getLowest(cutDepth, DEPTH_LEVELS);

    return {
      className: `cut-depth--${depthLevel}`,
    };
  }
  const isReviewModeLoading = useSelector(selectReviewModeLoading);
  return (
    <>
      {isReviewModeLoading && (
        <div className='sherpa-container--loading'>
          <Loading type='domino' />
        </div>
      )}
      {!isReviewModeLoading && (
        <div className='ui-layer'>
          <Viewport viewport={ui.viewport}>
            <ShadowGenerator />
            <Background viewport={ui.viewport} />
            <ReviewModeGroup extendGroup={extendGroup} />
          </Viewport>
        </div>
      )}
    </>
  );
}

function ShadowGenerator() {
  return (
    <>
      <filter id='shadow-1'>
        <feColorMatrix
          in='SourceGraphic'
          type='matrix'
          values='0 0 0 0 0
                  0 0 0 0 0
                  0 0 0 0 0
                  0 0 0 1 0'
          result='inset1'
        />
        <feGaussianBlur stdDeviation='0.8' />
        <feOffset dy='0.4' dx='0.4' />
        <feComposite operator='xor' in2='inset1' />
        <feComposite operator='in' in2='inset1' />
        <feBlend in2='SourceGraphic' mode='overlay' result='inset1-result' />
      </filter>
    </>
  );
}
