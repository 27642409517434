import React, { useRef, useState } from 'react';

import { asFloat, asInt } from '@/Utility/sanitize';

//actions
import { useAction } from '../../../Actions/useAction';
import UpdateSecretOptions from '@/Actions/UpdateSecretOptions';

// components
import InputField from '../../InputField/InputField';
import Switch from '@/Components/ModeMenu/Components/Switch';
import ClickCapture from '@/Components/ClickCapture/ClickCapture';
import AttachToElement from '@/Components/AttachToElement/AttachToElement';
import { HexColorPicker } from 'react-colorful';
import { createPortal } from '@/Utility/react';
import { MenuItem, Select, SelectChangeEvent } from '@mui/material';

export type InputProps<T> = {
  value: T;
  hasColor?: boolean;
  type?: string;
  sanitize?: <R>(value: R) => void;
  onChange?: <R>(value: R) => void;
  size?: string;
  prop: string;
  className?: string;
};

export default function Input<T>(props: InputProps<T>) {
  const inputRef = useRef(null);
  const [expanded, setExpanded] = useState(false);
  const [color, setColor] = useState(`#${props.value}`);
  const hasColor = props.hasColor;
  const [selectValue, setSelectValue] = useState<any>(props.value);

  const sanitize =
    props?.type === 'int'
      ? asInt
      : props?.type === 'number'
      ? asFloat
      : props.sanitize || ((val) => val);

  const inputType = props?.type === 'int' ? 'numeric' : 'text';

  const updateSecretOptionsAction = useAction(UpdateSecretOptions);

  function changeColor(event: string) {
    setColor(event);
    sanitizeChange(event?.substring(1));
  }

  function onExpand() {
    if (hasColor) {
      setExpanded(true);
    }
  }

  function onDismiss() {
    setExpanded(false);
  }

  function selectOnChange(value: SelectChangeEvent<string>) {
    const newValue = value?.target.value;
    onChange(newValue);
  }

  function sanitizeChange(value: any) {
    const val = sanitize(value);
    updateSecretOptionsAction.update({ [props.prop]: val });
  }

  function toggleOnChange(newValue: boolean) {
    setSelectValue(newValue);
    if (props.onChange) {
      props.onChange<boolean>(newValue);
    } else {
      sanitizeChange(newValue);
    }
  }

  function onChange(newValue: string) {
    setSelectValue(newValue);
    if (props.onChange) {
      props.onChange<string>(newValue);
    } else {
      sanitizeChange(newValue);
    }
  }

  const colorSelector = () =>
    createPortal(
      <ClickCapture
        className='as-overlay secret-menu-panel--color-picker--container'
        onClick={onDismiss}
      >
        <AttachToElement
          className='secret-menu-panel--color-picker--container--element'
          el={inputRef}
        >
          <HexColorPicker color={color} onChange={changeColor} />
        </AttachToElement>
      </ClickCapture>
    );

  const renderInput = () => {
    switch (props.type) {
      case 'toggle':
        return (
          <Switch active={props.value as boolean} onToggle={toggleOnChange} />
        );
      case 'dropdown':
        return (
          <Select value={selectValue} onChange={selectOnChange}>
            <MenuItem value='en'>English</MenuItem>
            <MenuItem value='de'>German</MenuItem>
            <MenuItem value='fr'>French</MenuItem>
          </Select>
        );
      default:
        return (
          <>
            {expanded && colorSelector()}
            <div onClick={onExpand}>
              <InputField
                size={props.size || 'large'}
                inputMode={inputType}
                selectAllOnFocus={true}
                value={props.value as string}
                onBlur={onChange}
                onCommit={onChange}
              />
            </div>
          </>
        );
    }
  };

  return (
    <div
      className={`secret-menu-panel--input ${props.className || ''}`}
      ref={inputRef}
    >
      {renderInput()}
      {hasColor && (
        <div className='secret-menu-panel--input--color' onClick={onExpand}>
          <svg viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'>
            <circle cx='10' cy='10' r='10' fill={color} />
          </svg>
        </div>
      )}
    </div>
  );
}
