import React from 'react';

//components
import FloatingPanel from './Components/FloatingPanel';
import { useSelector } from 'react-redux';
import { selectLocale } from '@/Redux/Slices/ShaperHubSlice';
import { setI18nLanguage } from '@/i18n';
import { setDeveloperSettings } from '@/Utility/developer-settings';
import { SecretMenuOption } from './Components/Group';

export default function SecretMenuPanel() {
  const { language } = useSelector(selectLocale);
  const gridOptions = [
    {
      label: 'Grid Snapping Threshold (px)',
      type: 'number',
      prop: 'gridSnappingThreshold',
    },
    {
      label: 'Smart Snapping Threshold (px)',
      type: 'number',
      prop: 'smartSnappingThreshold',
    },
    {
      label: 'Snapping Stroke',
      type: 'number',
      prop: 'guideLineWidth',
      prop2: 'guideLineColor',
      hasColor2: true,
    },
    {
      type: 'separator',
    },
  ];

  const guideOptions = [
    {
      label: 'Guide Unselected Path',
      type: 'number',
      prop: 'guideUnselectedPathWidth',
      prop2: 'guideUnselectedPathColor',
      hasColor2: true,
    },
    {
      label: 'Guide Unselected Hover Path',
      type: 'number',
      prop: 'guideUnselectedHoverPathWidth',
      prop2: 'guideUnselectedHoverPathColor',
      hasColor2: true,
    },
    {
      label: 'Guide Glow Path',
      type: 'number',
      prop: 'guideGlowPathWidth',
      prop2: 'guideGlowPathColor',
      hasColor2: true,
    },
    {
      label: 'Guide Selected Path',
      type: 'number',
      prop: 'guideSelectedPathWidth',
      prop2: 'guideSelectedPathColor',
      hasColor2: true,
    },
    {
      label: 'Guide Selected Hover Path',
      type: 'number',
      prop: 'guideSelectedHoverPathWidth',
      prop2: 'guideSelectedHoverPathColor',
      hasColor2: true,
    },
    {
      type: 'separator',
    },
  ];

  const objectOptions = [
    {
      label: 'Object Unselected Path',
      type: 'number',
      prop: 'objectUnselectedPathWidth',
      prop2: 'objectUnselectedPathColor',
      hasColor2: true,
    },
    {
      label: 'Object Unselected Hover Path',
      type: 'number',
      prop: 'objectUnselectedHoverPathWidth',
      prop2: 'objectUnselectedHoverPathColor',
      hasColor2: true,
    },
    {
      label: 'Object Selected Path',
      type: 'number',
      prop: 'objectSelectedPathWidth',
      prop2: 'objectSelectedPathColor',
      hasColor2: true,
    },
    {
      label: 'Object Selected Hover Path',
      type: 'number',
      prop: 'objectSelectedHoverPathWidth',
      prop2: 'objectSelectedHoverPathColor',
      hasColor2: true,
    },
    {
      type: 'separator',
    },
  ];

  const positionOptions = [
    {
      label: 'Position Display Stroke',
      prop: 'positionStrokeWidth',
      prop2: 'positionColor',
      hasColor2: true,
    },
    {
      label: 'Position Display Dash Width (px)',
      type: 'number',
      prop: 'positionDashWidth',
    },
    {
      label: 'Position Display Gap Width (px)',
      type: 'number',
      prop: 'positionGapWidth',
    },
  ];

  const otherOptions = [
    {
      label: 'Display Workspace Sync Options',
      type: 'toggle',
      prop: 'showWorkspaceSettings',
    },
    {
      label: 'Language',
      type: 'dropdown',
      prop: 'language',
      value: language || 'en',
      onChange: (value: string) => {
        if (value !== language) {
          setDeveloperSettings('locale', value);
          setI18nLanguage(value);
        }
      },
    },
  ];

  return (
    <div className='secret-menu-panel--content'>
      {[
        ...gridOptions,
        ...guideOptions,
        ...objectOptions,
        ...positionOptions,
        ...otherOptions,
      ].map((opt, index) => {
        if (opt.type === 'seperator') {
          return <FloatingPanel.Separator />;
        } else if (opt.type === 'number') {
          return (
            <FloatingPanel.Group<number>
              key={index}
              {...(opt as SecretMenuOption<number>)}
            />
          );
        } else if (opt.type === 'toggle') {
          return (
            <FloatingPanel.Group<boolean>
              key={index}
              {...(opt as SecretMenuOption<boolean>)}
            />
          );
        }
        return (
          <FloatingPanel.Group<string>
            key={index}
            {...(opt as SecretMenuOption<string>)}
          />
        );
      })}
    </div>
  );
}
