import React, {
  useRef,
  useCallback,
  useLayoutEffect,
  useEffect,
  KeyboardEvent,
  useState,
  MouseEvent,
  MutableRefObject,
} from 'react';
import { useSelector } from 'react-redux';
import { createPortal, extractDropdownList } from '@/Utility/react';

// components
import ClickCapture from '@/Components/ClickCapture/ClickCapture';
import AttachToElement from '@/Components/AttachToElement/AttachToElement';
import { Tooltip } from '@/Components/Tooltip/Tooltip';
import Icon from '@/Styles/Icons/Icon';
import classNames from 'classnames';
import { isTouchDevice } from '@/Utility/isTouchDevice';
import { UP_ARROW, DOWN_ARROW, ENTER_KEY, ESC_KEY } from '@/Constants/UI';
import { cancelEvent } from '@/Utility/events';
import { useTranslation } from 'react-i18next';
import { selectIsMobile } from '@/Redux/Slices/UISlice';

/* Dropdown - a suped-up <select>
 *
 * The most basic use case requires the two main components:
 * - Dropdown - The wrapper component for the dropdown
 * - Dropdown.Item - Represents each selectable option of the dropdown
 * With this basic structure:
 * <Dropdown>
 *   <Dropdown.Item key='value1' value='value1'>Value 1</Dropdown.Item>
 *   <Dropdown.Item key='value2' value='value2'>Value 2</Dropdown.Item>
 *   ...
 * </Dropdown>
 *
 * This dropdown component also supports some more complicated features:
 *
 * Grouping:
 * ---------
 * Dropdown items can be shown to be in groups with dividers, by including
 * Dropdown.Group components mixed with the Dropdown.Item components:
 * <Dropdown>
 *   <Dropdown.Group label={"Group 1"} />
 *   <Dropdown.Item key='value1' value='value1'>Value 1</Dropdown.Item>
 *   <Dropdown.Group label={"Group 2"} />
 *   <Dropdown.Item key='value2' value='value2'>Value 2</Dropdown.Item>
 *   ...
 * </Dropdown>
 *
 * Filters:
 * --------
 * Dropdown items can be filtered using filters, which will appear as a separate
 * section at the top of the dropdown. Filters can either be enabled or not, and
 * combine as an AND (i.e. if filter1 and filter2 are enabled, the shown items
 *                    are filtered by filter1 AND filter2)
 *
 * Previews:
 * ---------
 * When hovering (with a mouse) or tap-and-hold-ing on dropdown items, onPreview
 * (and onPreviewEnd when finished) are called. Those functions can then be setup
 * to show/unshow a preview of the selection.
 *
 * SubItems with Secondary Values:
 * -------------------------------
 * Dropdown items can have secondary values, allowing the dropdown to select multiple
 * values at once. These secondary values are specified through a dropdown item's subItems,
 * which appear as an expandable list of secondary values.
 * <Dropdown>
 *   <Dropdown.Item key='value1' value='value1' secondaryValue='secondary1'>Value 1</Dropdown.Item>
 *   <Dropdown.Item key='value2' value='value2' secondaryValue='secondary1' subItems={[
 *     <Dropdown.Item key='value2_secondary1' value='value2' secondaryValue='secondary1'>Secondary 1</Dropdown.Item>
 *     <Dropdown.Item key='value2_secondary2' value='value2' secondaryValue='secondary2'>Secondary 2</Dropdown.Item>
 *   ]}>Value 2</Dropdown.Item>
 *   ...
 * </Dropdown>
 */

// TODO: Move this definition to extractDropdownList once its TypeScript
type DropdownList = {
  options: React.ReactElement[];
  values: React.ReactElement[];
  items: React.ReactElement[];
};

type Props<T, U> = {
  children?: React.ReactNode;
  className?: string;
  dataCy: string;
  key?: string;

  // Which primary value is selected
  value: T;
  // Which secondary value (if any) is selected
  secondaryValue?: U;

  // What filters does this dropdown have available, (if any)?
  filters?: React.ReactNode;

  disabled?: boolean;
  expandIcon?: boolean;

  // When a selection is made, this callback is called
  onChange: (selection: T, secondarySelection?: U) => void;

  onPreview?: (selection: T, secondarySelection?: U) => void;
  onPreviewEnd?: (selection?: T, secondarySelection?: U) => void;

  // When the dropdown is opened or closed, these callbacks are called
  onExpand?: () => void;
  onDismiss?: () => void;
};

export default function Dropdown<T, U>(props: Props<T, U>) {
  const dropdownRef = useRef<HTMLButtonElement>(null);
  const listRef = useRef<HTMLDivElement>(null);

  // state
  const [expanded, setExpanded] = useState(false);

  // computed
  const getDropdown = useCallback(
    () => extractDropdownList(props) as DropdownList,
    [props.children]
  );

  const isMobile = useSelector(selectIsMobile);

  // options
  const dropdown = getDropdown();
  const selectedIndex = (() => {
    const found = dropdown.options.findIndex(
      (item) => item.props.value === props.value
    );
    return found !== -1 ? found : 0;
  })();
  const selected = dropdown.options[selectedIndex];
  const selectedSecondary =
    props.secondaryValue &&
    selected.props.subItems &&
    selected.props.subItems.find(
      (item: React.ReactElement) => item.props.value === props.secondaryValue
    );

  // There's two types of preview:
  // - a temporary preview, done through either a mouse hover or tap and hold
  // - a persistent preview from hitting the up/down keys, which can either
  //     be accepted (ENTER) or rejected (ESC)
  // This tracks the state for the persistent preview
  const [previewIndex, setPreviewIndex] = useState(selectedIndex);

  const onKeyUp = (event: KeyboardEvent) => {
    if (event.key === UP_ARROW.code) {
      const nextIndex = Math.max(previewIndex - 1, 0);
      setPreviewIndex(nextIndex);
      onPreview(
        dropdown.options[nextIndex].props.value,
        dropdown.options[nextIndex].props.secondaryValue
      );
      cancelEvent(event);
    } else if (event.key === DOWN_ARROW.code) {
      const nextIndex = Math.min(previewIndex + 1, dropdown.options.length - 1);
      setPreviewIndex(nextIndex);
      onPreview(
        dropdown.options[nextIndex].props.value,
        dropdown.options[nextIndex].props.secondaryValue
      );
      cancelEvent(event);
    } else if (event.key === ENTER_KEY.code) {
      handleClickCapture();
      cancelEvent(event);
    } else if (event.key === ESC_KEY.code) {
      onPreviewEnd();
      onDismiss();
      cancelEvent(event);
    }
  };

  const onPanelClick = (event: MouseEvent) => {
    if (expanded) {
      setImmediate(() => {
        if (dropdownRef.current) {
          dropdownRef.current.focus();
        }
      });
    }
  };

  const onDropdownUnfocused = () => {
    if (expanded) {
      setImmediate(() => {
        if (dropdownRef.current) {
          dropdownRef.current.focus();
        }
      });
    }
  };

  useEffect(() => {
    if (!expanded) {
      setImmediate(() => {
        if (dropdownRef.current) {
          dropdownRef.current.blur();
        }
      });
    }
  }, [expanded]);

  function focusOnSelectedItem() {
    const item = listRef.current?.querySelector('.selected:not(.disabled)');
    const container = item?.closest('.scrollable');
    if (item && container) {
      const { top: listTop, height: listHeight } =
        container.getBoundingClientRect();
      const { top: itemTop, height: itemHeight } = item.getBoundingClientRect();
      container.scrollTop +=
        itemTop - listTop - ((listHeight - itemHeight) >> 1);
    }
  }

  // clearing the menu
  function onDismiss() {
    setExpanded(false);
    if (props.onDismiss) {
      props.onDismiss();
    }
  }

  const handleClickCapture = () => {
    if (previewIndex !== selectedIndex) {
      onSelect(
        dropdown.options[previewIndex].props.value,
        dropdown.options[previewIndex].props.secondaryValue
      );
    } else {
      onDismiss();
    }
  };

  // shows the menu
  function onExpand() {
    if (!props.disabled && !expanded) {
      setExpanded(true);
      setPreviewIndex(selectedIndex);
      if (props.onExpand) {
        props.onExpand();
      }
    }
  }

  const onPreview = (selection: T, secondarySelection?: U) => {
    if (props.onPreview) {
      props.onPreview(selection, secondarySelection);
    }
  };

  const onPreviewEnd = (selection?: T, secondarySelection?: U) => {
    if (previewIndex !== selectedIndex) {
      onPreview(
        dropdown.options[previewIndex].props.value,
        dropdown.options[previewIndex].props.secondaryValue
      );
    } else {
      if (props.onPreviewEnd) {
        props.onPreviewEnd(selection, secondarySelection);
      }
    }
  };

  // handle new option selections
  function onSelect(selection: T, secondarySelection: U) {
    if (
      selection !== selected.props.value ||
      secondarySelection !== selectedSecondary?.props?.value
    ) {
      props.onChange(selection, secondarySelection);
    }

    setExpanded(false);
  }

  // set focus on selected item when expanding is changed
  useLayoutEffect(focusOnSelectedItem, [expanded, selectedIndex, previewIndex]);

  // renders the list
  function renderDropdownList() {
    return createPortal(
      <ClickCapture
        onClick={handleClickCapture}
        className='as-overlay properties-panel--dropdown-list--container'
      >
        <AttachToElement
          className={`properties-panel--dropdown-list--offset ${
            props.className || ''
          }`}
          el={dropdownRef}
          side={isMobile ? 'bottom right' : 'top right'}
        >
          <div
            ref={listRef}
            className={`properties-panel--dropdown-list--area ${
              props.className || ''
            }`}
            onClick={onPanelClick}
            data-cy={props.dataCy}
          >
            <div
              ref={listRef}
              className={`properties-panel--dropdown-list--area-contents ${
                props.className || ''
              }`}
              data-cy={props.dataCy}
            >
              {props.filters && (
                <div
                  ref={listRef}
                  className={`properties-panel--filters ${
                    props.className || ''
                  }`}
                  data-cy={props.dataCy}
                >
                  {props.filters}
                </div>
              )}
              <div
                ref={listRef}
                className={`properties-panel--dropdown-list-container  ${
                  props.className || ''
                } ${props.filters ? 'has-filters' : ''}`}
                data-cy={props.dataCy}
              >
                <div
                  ref={listRef}
                  className={`properties-panel--dropdown-list scrollable  ${
                    props.className || ''
                  } ${props.filters ? 'has-filters' : ''}`}
                  data-cy={props.dataCy}
                >
                  {dropdown.items
                    .filter((child) => !child.props.hidden)
                    .map((child, index) =>
                      React.cloneElement(child, {
                        key: `item_${index}`,
                        ...(previewIndex === selectedIndex
                          ? {
                              selected: child === selected,
                              selectedSecondaryValue: props.secondaryValue,
                            }
                          : {
                              selected: index === previewIndex,
                            }),
                        onSelect,
                        onPreview,
                        onPreviewEnd,
                      })
                    )}
                </div>
              </div>
            </div>
          </div>
        </AttachToElement>
      </ClickCapture>,
      null,
      { appendOnly: true }
    );
  }

  const dropdownCx = classNames(
    `properties-panel--dropdown ${props.className || ''}`,
    { expanded: expanded, disabled: props.disabled }
  );

  return (
    <>
      {expanded && renderDropdownList()}
      <button
        key={props.key}
        className={dropdownCx}
        ref={dropdownRef}
        onClick={onExpand}
        onBlur={onDropdownUnfocused}
        onKeyUp={onKeyUp}
        data-cy={props.dataCy}
      >
        {React.cloneElement(selected, {
          onPreview: () => {},
          onPreviewEnd: () => {},
        })}

        <div className='properties-panel--dropdown--expander'>
          <Icon icon={props.expandIcon ?? 'chevron-right'} />
        </div>
      </button>
    </>
  );
}

type ItemProps<T, U> = {
  key: string;
  children?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  dataCy?: string;

  // Which value and secondary value to set if this item is selected
  value: T;
  secondaryValue?: U;

  // Whether this item is the selected on
  selected?: boolean;

  // If this is specified, this item will be expandable to show subItems
  subItems?: React.ReactNode[];
  // This is which secondary value is selected, passed in by the Dropdown
  selectedSecondaryValue?: string;

  icon?: React.ReactNode;
  hidden?: boolean;
  disabled?: boolean;
  // A cursed prop that lets us show a preview of the option even when it's disabled
  // (used for showing users a preview of premium features they don't have)
  previewDisabled?: boolean;

  onSelect?: (selection: T, secondarySelection?: U) => void;
  onPreview?: (selection: T, secondarySelection?: U) => void;
  onPreviewEnd?: (selection: T, secondarySelection?: U) => void;
};

const DropdownItem = function <T, U>(props: ItemProps<T, U>) {
  const {
    icon,
    selected,
    selectedSecondaryValue,
    value,
    secondaryValue,
    subItems,
    dataCy,
    disabled,
    key,
    previewDisabled = disabled,
  } = props;
  const hasIcon = !!icon;
  const expandable = subItems && subItems.length > 0;

  const [expanded, setExpanded] = useState(false);

  const getDropdown = useCallback(
    () => extractDropdownList({ children: props.subItems }) as DropdownList,
    [props.subItems]
  );

  const isLongPress = useRef(false);
  const touchTimer: MutableRefObject<ReturnType<typeof setTimeout> | null> =
    useRef(null);

  // options
  const dropdown = getDropdown();

  function onSelectValue(selection: T, secondarySelection?: U) {
    if (props.onSelect && selection && !disabled) {
      props.onSelect(selection, secondarySelection);
    }
  }

  const onPreview = (selection: T, secondarySelection?: U) => {
    if (!previewDisabled && props.onPreview) {
      props.onPreview(selection, secondarySelection);
    }
  };

  const onPreviewEnd = () => {
    if (!disabled && props.onPreviewEnd) {
      props.onPreviewEnd(value, secondaryValue);
    }
  };

  const onTouchStart = () => {
    isLongPress.current = false;
    touchTimer.current = setTimeout(() => {
      isLongPress.current = true;
      onPreview(value, secondaryValue);
    }, 250);
  };

  const onTouchEnd = () => {
    if (touchTimer.current) {
      clearTimeout(touchTimer.current);
      touchTimer.current = null;
    }

    onPreviewEnd();
  };

  const onClick = () => {
    if (isLongPress.current) {
      return;
    }

    onSelectValue(value);
  };

  const onMouseEnter = () => {
    if (!isTouchDevice(window)) {
      onPreview(value, secondaryValue);
    }
  };

  const onMouseLeave = () => {
    if (!isTouchDevice(window)) {
      onTouchEnd();
    }
  };

  const toggleExpanded = (e: MouseEvent) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    if (!disabled) {
      setExpanded(!expanded);
    }
  };

  const itemCx = classNames(
    'properties-panel--dropdown-item--row',
    {
      'with-icon': hasIcon,
      selected,
      disabled,
    },
    props.className
  );

  return (
    <div key={key} className='properties-panel--dropdown-item'>
      <div
        className={itemCx}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onTouchStart={onTouchStart}
        onTouchEnd={onTouchEnd}
        data-cy={dataCy}
      >
        <div className='properties-panel--dropdown-item--row-button'>
          {props.icon && (
            <div className='properties-panel--dropdown-item--row-icon'>
              <Icon icon={props.icon} />
            </div>
          )}
          <div
            className='properties-panel--dropdown-item--row-label'
            style={props.style}
          >
            {props.children}
          </div>
        </div>
        {expandable && (
          <div
            className='properties-panel--dropdown-item--row-expander'
            onClick={toggleExpanded}
          >
            <Icon icon={expanded ? 'unfold-less' : 'unfold-more'} />
          </div>
        )}
      </div>
      {expandable && (
        <div
          className={classNames('properties-panel--dropdown-item--subItems', {
            expanded,
          })}
        >
          {dropdown.items
            .filter((child) => !child.props.hidden)
            .map((child, index) =>
              React.cloneElement(child, {
                key: `item_${index}`,
                selected: child.props.secondaryValue === selectedSecondaryValue,
                onSelect: () =>
                  onSelectValue(child.props.value, child.props.secondaryValue),
                onPreview,
                onPreviewEnd,
              })
            )}
        </div>
      )}
    </div>
  );
};

type GroupProps = {
  className: string;

  label: React.ReactNode;
};
// placeholders for creating React components
const DropdownGroup = function (props: GroupProps) {
  return (
    <div
      className={`properties-panel--dropdown-group ${props.className || ''}`}
    >
      {props.label}
    </div>
  );
};

type FilterProps = {
  className: string;
  dataCy: string;
  i18nKey: string;

  selected: string;
  value: string;

  icon: React.ReactNode;
  tooltip: React.ReactNode;

  onSelect: (selection: string) => void;
};

const DropdownFilter = function (props: FilterProps) {
  const tooltipRef = useRef<HTMLDivElement>(null);
  const { selected, icon, tooltip, dataCy, i18nKey } = props;

  const { t, i18n } = useTranslation();

  const tooltipTranslation = i18n.exists(i18nKey) ? t(i18nKey) : tooltip;

  function onSelect() {
    if (props.onSelect) {
      props.onSelect(props.value);
    }
  }

  const itemCx = classNames(
    'properties-panel--filters-filter',
    {
      selected,
    },
    props.className
  );

  return (
    <div className={itemCx} onClick={onSelect} data-cy={dataCy}>
      <Tooltip
        side='above'
        tip={tooltipTranslation}
        absoluteRef={tooltipRef as MutableRefObject<HTMLElement>}
      >
        <div className='properties-panel--filters-filter-icon' ref={tooltipRef}>
          <Icon icon={icon} />
        </div>
      </Tooltip>
    </div>
  );
};

Dropdown.Item = DropdownItem;
Dropdown.Group = DropdownGroup;
Dropdown.Filter = DropdownFilter;

// silly extra step required since there's a weird
// issue with type checking on compiled versions
// @ts-ignore
Dropdown.Group.isDropdownGroup = true;
// @ts-ignore
Dropdown.Item.isDropdownItem = true;
