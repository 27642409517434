import BaseInteraction from '@/InteractionsManager/Interactions/BaseInteraction';

// consts
import {
  EDGE_HIT_DETECTION_EXPANSION,
  SHIFT_LEFT_KEY,
  SHIFT_RIGHT_KEY,
} from '../../../../Constants/UI';

// util
import HitDetection from '@/Helpers/HitDetection';
import { signedArea } from '../../../../Geometry/PathOps';
import * as hoverStateHelper from '../../../../Components/HoverStateHelper/HoverStateHelper';

// selectors
import { selectSelectedPathIds } from '@/Redux/Slices/SelectionSlice';

// actions
import SetSelectionAction from '@/Actions/SetSelection';
import { addAttributeToUser } from '../../../../Utility/userflow';
import SetCursorAction from '../../../../Actions/SetCursor';

export default class SelectPathInteraction extends BaseInteraction {
  refreshHoverState = (
    position,
    over = this.hitTestPaths(position),
    isTouch
  ) => {
    const { useSelector } = this;

    // update for hovers
    const selection = useSelector(selectSelectedPathIds);

    // try and find the next selection
    let lastIndex = -1;
    for (let i = 0; i < over.length; i++) {
      if (
        selection.findIndex((selected) => selected.pathId === over[i].pathId) >
        -1
      ) {
        lastIndex = i;
      }
    }

    // find the next layer to select
    const next = over[(lastIndex + 1) % over.length];
    if (next) {
      hoverStateHelper.register({
        groupId: next.groupId,
        pathId: next.pathId,
        options: { isTouch },
      });
    } else {
      hoverStateHelper.clear();
    }
  };

  hitTestPaths({ x, y }) {
    const over = [];
    const svgGroups = this.getSvgGroups();
    const nspf = this.getNonScalingPixelFactor();

    // perform hit detection
    const groups = document.querySelectorAll('svg.ui-viewport .pathGroup');
    for (const group of groups) {
      const paths = group.querySelectorAll('.toolWidth, .basePath');

      // check for certain types that may change
      // how we do hit detection
      const pocket = group.classList.contains('cut-type-pocket');

      // tracking if this path is kept or not
      let keep;

      // check each of the sub paths
      for (const path of paths) {
        const tool = path.classList.contains('toolWidth');

        // when checking a pocket, use the fill
        let stroke;
        if (pocket && tool) {
          stroke = null;
        }
        // extract stroke info, if available
        else {
          stroke =
            Math.max(
              parseInt(path.getAttribute('stroke-width'), 10) || 0,
              EDGE_HIT_DETECTION_EXPANSION
            ) * nspf;
        }

        // check for a hit
        const hit = HitDetection.hitTestSVG(path, x, y, { stroke });

        // if this is a hit, then we're done
        if (hit) {
          keep = true;
          break;
        }
      }

      // TODO: refactor extracting path and group Ids
      // when being kept, we need to extract the ID
      // there's probably a better way to do this, but for now
      // just grab the sub strings
      if (keep) {
        const groupId = group.id.substr(13, 36);
        const pathId = group.id.substr(53, 36);
        over.push({ groupId, pathId });
      }
    }

    // order by area
    const sorted = over.map((selection) => {
      let area = this.calculatedAreas[selection.pathId];

      // calculate the new area
      if (!area) {
        const group = svgGroups.find((t) => t.id === selection.groupId);
        const path = group.basePathSet.find((p) => p.id === selection.pathId);
        area =
          this.calculatedAreas[selection.pathId] ||
          Math.abs(signedArea(path.outerPath || path));
      }

      return { area, selection };
    });

    sorted.sort((a, b) => a.area - b.area);

    return sorted.map((item) => item.selection);
  }

  calculatedAreas = {};

  onMouseMove(event) {
    const over = this.hitTestPaths(event.center);
    this.refreshHoverState(event.center, over);
  }

  // checks for events that should append the targeted layer
  // onto the current selection
  shouldAppendToSelection(event) {
    return event.shiftKey || /touch/i.test(event.pointerType) || this.longPress;
  }

  onKeyDown(event) {
    addAttributeToUser('key_pressed', event.key);
    if (this.isKey(event, SHIFT_LEFT_KEY, SHIFT_RIGHT_KEY)) {
      const cursorAction = this.createAction(SetCursorAction);
      cursorAction.toMultiSelect();
    }
  }

  onKeyUp(event) {
    addAttributeToUser('key_pressed', null);
    if (this.isKey(event, SHIFT_LEFT_KEY, SHIFT_RIGHT_KEY)) {
      const cursorAction = this.createAction(SetCursorAction);

      cursorAction.toDefault();
    }
  }

  onEveryLongPressActivate() {
    this.longPress = true;
  }

  onLongPressRelease() {
    this.longPress = false;
  }

  onPointerUp(event, manager) {
    const { isDragging } = manager;
    if (!isDragging) {
      const selection = this.createAction(SetSelectionAction);
      const selectedPathIds = this.getSelectedPathIds();

      // find what's being selected over
      let selected = this.hitTestPaths(event.center) || [];

      // special rules
      if (!selected.length && !this.longPress) {
        this.refreshMenuState({ reason: 'void-click' });
        return true;
      }

      // if multi-select, append to the selection
      if (this.shouldAppendToSelection(event) && selectedPathIds.length) {
        // find the next item to
        const remaining = selected.filter(
          (item) => !selectedPathIds.find((path) => path.pathId === item.pathId)
        );

        // if there's anything to add on
        if (remaining.length) {
          const updated = [...selectedPathIds, remaining[0]];
          selection.set(updated);
        }
        // check if we need to deselect a path
        else if (selected.length) {
          const [remove] = selected;
          const updated = selectedPathIds.filter(
            (path) => path.pathId !== remove.pathId
          );
          selection.set(updated);
        }
      } else {
        // nothing was even selected
        if (!selected.length && !this.longPress) {
          return selection.clear();
        }

        // find a new target
        let target;

        // cycle the selection
        if (selectedPathIds.length) {
          const current = selectedPathIds[selectedPathIds.length - 1];
          const index = selected.findIndex(
            (item) => item.pathId === current.pathId
          );

          // cycle to the next in the selection
          target = selected[(index + 1) % selected.length];
        } else {
          [target] = selected;
        }

        // replace the target
        selection.set([target]);
      }

      // always try and refresh
      const { x, y } = event.center;
      const { isTouch } = event;
      setTimeout(() => this.refreshHoverState({ x, y }, undefined, isTouch));
    } else {
      manager.isDragging = false;
    }
  }
}
