import {
  setUsername,
  loginShaperHub,
  getShaperSubscriptions,
  getUser,
} from '@/Redux/Slices/ShaperHubSlice';
import { selectFeaturesByMode } from '@/Redux/Slices/UISlice';
import * as Sentry from '@sentry/browser';
import { getFirstWorkspace } from '../ShaperHub/ShaperHubThunks';
import { CustomErrors } from '../Constants/Errors';
import {
  selectSnapshot,
  selectWorkspaceIdFromUrlParam,
  setEnabled,
  setStatus,
} from '@/Redux/Slices/SyncSlice';
import { create, open } from '@/Sync/SyncThunks';
import InitializeAppAction from './InitializeApp';
import { entitlements } from '@/Helpers/Entitlements';
import { AppDispatch } from '@/Redux/store';
import { UseSelector } from './useAction';

export default class LogInAction {
  dispatch: AppDispatch;
  useSelector: UseSelector;

  constructor(dispatch: AppDispatch, useSelector: UseSelector) {
    this.dispatch = dispatch;
    this.useSelector = useSelector;
  }

  logIn = async (username: string, password: string) => {
    const { dispatch, useSelector } = this;
    try {
      await dispatch(loginShaperHub({ u: username, p: password })).unwrap();
      dispatch(setStatus('disconnected'));
      dispatch(getUser());
      dispatch(setUsername(username));
      dispatch(getShaperSubscriptions());
      dispatch(getFirstWorkspace());

      const useSync = this.useSelector((state) =>
        selectFeaturesByMode(state, entitlements.SYNC)
      );
      if (useSync) {
        dispatch(setEnabled(true));
        const workspaceIdFromUrlParam = useSelector(
          selectWorkspaceIdFromUrlParam
        );
        const snapshot = useSelector(selectSnapshot);
        if (workspaceIdFromUrlParam) {
          dispatch(open({ workspaceId: workspaceIdFromUrlParam }));
          return;
        }
        if (snapshot.canvas === null) {
          const initializeAppAction = new InitializeAppAction(
            dispatch,
            useSelector
          );
          const workspaceId =
            await initializeAppAction.getMostRecentActiveWorkspaceId();
          if (workspaceId) {
            dispatch(
              open({
                workspaceId,
              })
            );
            return;
          }
        }
        dispatch(create({}));
      }
    } catch (e: any) {
      //Http errors are not JS errors and sentry barks about this.
      const error =
        e.constructor.name !== 'Error' ? new Error(JSON.stringify(e)) : e;
      if (
        Sentry.isInitialized() &&
        Object.keys(error)?.length > 0 &&
        !Object.values(CustomErrors).includes(e.message)
      ) {
        Sentry.captureException(error);
      }

      // this will only work on localhost or staging environments
      if (e.message === CustomErrors.WHITELIST_IP) {
        return CustomErrors.WHITELIST_IP;
      }
      return true;
    }
  };
}
