//selectors
import { useSelector } from 'react-redux';
import {
  SecretOptions,
  selectSecretOptions,
} from '../../../Redux/Slices/SherpaContainerSlice';

//components
import Input from './Input';
import Label from './Label';

export type SecretMenuOption<T> = {
  label: string;
  type?: string;
  prop: keyof SecretOptions;
  hasColor?: boolean;

  type2?: string;
  prop2?: keyof SecretOptions;
  hasColor2?: boolean;

  value?: T;
  onChange?: <R>(value: R) => void;
};

export default function Group<T>({
  label,
  type,
  prop,
  type2,
  prop2,
  hasColor,
  hasColor2,
  value,
  onChange,
}: SecretMenuOption<T>) {
  const secretOptions = useSelector(selectSecretOptions);

  const attrs = {
    type,
    prop,
    hasColor,
    value: value || secretOptions[prop],
    onChange,
  };

  const attrs2 = {
    type: type2 || '',
    prop: prop2 || '',
    hasColor: hasColor2 || false,
    className: 'secondProp',
    value: secretOptions[prop2 as keyof SecretOptions],
  };

  return (
    <div className='secret-menu-panel--group'>
      <Label>{label}</Label>
      <Input {...attrs} />
      {prop2 ? (
        <Input {...attrs2} />
      ) : (
        <div className='secret-menu-panel--empty' />
      )}
    </div>
  );
}
