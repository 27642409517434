import { createAction } from '@reduxjs/toolkit';
import {
  CreateOptions,
  DisconnectInterface,
  OpenOptions,
  Snapshot,
} from './SyncConstants';
// eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
import axios, { AxiosError, AxiosResponse } from 'axios';
import { getShaperAccessToken } from '@/ShaperHub/ShaperHubThunks';
import {
  UpdatePatch,
  isInvalidCanvasVersion,
  validatePatches,
} from './PatchGenerator';
import { copyText } from '@/defaults';
import {
  log,
  sanitizePatches,
  sanitizeResponse,
  sanitizeSnapshotResponse,
  setContextProperty,
} from './SyncLog';
import { SyncError } from './SyncError';
import { RootState } from '@/Redux/store';
import { createAppAsyncThunk } from '@/Redux/hooks';
import { Workspace } from '@/@types/shaper-types';
import { reject } from 'lodash';
import { updateSnapshot } from './SyncHelpers';
import { loadSandboxWorkspace } from '@/Redux/localStorage';
import { Operation } from 'fast-json-patch';

export const syncAxios = axios.create({});

export const getBaseUrl: Function = (store: RootState): string => {
  const { syncUrl } = store.sync;
  if (syncUrl) {
    return `${syncUrl}/workspaces`;
  }
  return 'https://workspaces.staging.shapertools.com/workspaces';
};

const context = { syncLevel: 'thunk' };

const throwGenericError = (message: string, error: any) => {
  return new SyncError(
    'generic_error',
    'thunk',
    `${message}: ${error?.response?.statusText}`,
    error
  );
};

syncAxios.interceptors.request.use(async (request) => {
  const token = await getShaperAccessToken();
  request.headers.Authorization = `Bearer ${token}`;
  return request;
});

const setSandboxMode = createAppAsyncThunk('sync/setSandboxMode', async () => {
  try {
    const sandbox = await loadSandboxWorkspace();
    return sandbox;
  } catch {
    return {
      canvas: null,
    };
  }
});

const disconnect = createAction<Partial<DisconnectInterface> | undefined>(
  'sync/disconnect'
);

const reconnect = createAction('sync/reconnect');

const open = createAppAsyncThunk(
  'sync/open',
  async (options: OpenOptions, { getState, rejectWithValue }) => {
    const { workspaceId } = options;

    setContextProperty({ key: 'workspaceId', value: workspaceId });
    log(
      `In open thunk for ${workspaceId}`,
      {
        ...context,
        thunkArgs: options,
      },
      'debug'
    );

    try {
      const store = getState();

      /**
       * gets the status
       */
      const response = await syncAxios.get(
        `${getBaseUrl(store)}/${workspaceId}/status`
      );
      const status = await response.data;

      log(
        `Received open thunk status`,
        {
          ...context,
          response: sanitizeResponse(response),
        },
        'debug'
      );

      return status;
    } catch (error: any) {
      const errorToReturn = ((): SyncError => {
        if (error.response.status === 404) {
          return new SyncError(
            'unknown_workspace',
            'thunk',
            `Error opening workspace: ${error?.response?.statusText}`,
            error,
            context
          );
        } else if (error.response.status === 500) {
          return new SyncError(
            'network_error',
            'thunk',
            `Error opening workspace: ${error?.response?.statusText}`,
            error,
            context
          );
        }
        return throwGenericError('Error opening workspace', error);
      })();
      return rejectWithValue(errorToReturn);
    }
  },
  {
    condition: (options: OpenOptions, { getState }) => {
      const { workspaceId } = options;
      const { sync } = getState() as RootState;
      const { enabled, status } = sync;
      if (enabled && (status === 'disconnected' || status === 'reconnecting')) {
        log(
          `Preparing to call open thunk for ${workspaceId} because sync is enabled and status is: ${status}`,
          { ...context },
          'debug'
        );
        return true;
      }
      log(
        `Unable to call open thunk for ${workspaceId} because sync is either not or status is: ${status}`,
        { ...context },
        'debug'
      );
      return false;
    },
  }
);

const create = createAppAsyncThunk(
  'sync/create',
  async (options: CreateOptions, { getState, rejectWithValue }) => {
    try {
      const store = getState();
      const params = {
        ...(options?.path && { path: options.path }),
      };

      log(
        `In create thunk`,
        {
          ...context,
          thunkArgs: options,
        },
        'debug'
      );

      /**
       * creates the workspace
       */
      const response = await syncAxios.post(`${getBaseUrl(store)}/`, {
        params,
      });

      const data = await response.data;
      log(`Received create thunk response`, {
        ...context,
        response: sanitizeResponse(response),
        params,
      });
      setContextProperty({ key: 'workspaceId', value: data.id });
      return data;
    } catch (error: unknown) {
      return rejectWithValue(
        throwGenericError('Error creating workspace', error)
      );
    }
  },
  {
    condition: (_, { getState }) => {
      const { sync } = getState() as RootState;
      const { enabled, status } = sync;
      if (enabled && status === 'disconnected') {
        log(
          `Preparing to call create thunk because sync is enabled and status is: ${status}`,
          { ...context },
          'debug'
        );
        return true;
      }
      log(
        `Unable to call create thunk because sync is either not or status is: ${status}`,
        { ...context },
        'debug'
      );
      return false;
    },
  }
);

export interface GetSnapshotObject {
  workspace: Workspace;
  blobId: string;
  isDuplicate?: boolean;
}

const getSnapshot = createAppAsyncThunk(
  'sync/getSnapshot',
  async (snapshotObject: GetSnapshotObject, { getState, rejectWithValue }) => {
    const { workspace } = snapshotObject;
    const { id, latestSnapshotSequence } = workspace;
    const store = getState();
    try {
      const response = await syncAxios.get(
        `${getBaseUrl(store)}/${id}/snapshot/${latestSnapshotSequence}`
      );

      const snapshot = await response.data;

      if (isInvalidCanvasVersion(snapshot.canvas)) {
        throw new SyncError(
          'invalid-version',
          'listener',
          'Invalid canvas version',
          undefined,
          sanitizeSnapshotResponse(snapshot)
        );
      }

      log(
        `Getting the snapshot was successful!`,
        {
          ...context,
          response: sanitizeResponse(response, false),
          snapshot: sanitizeSnapshotResponse(snapshot),
        },
        'debug'
      );

      updateSnapshot(snapshot);

      return true;
    } catch (error: unknown | AxiosError) {
      const errorToReturn = ((): SyncError => {
        if (error instanceof SyncError) {
          return error;
        } else if (error && axios.isAxiosError(error) && error.response) {
          switch (error.response.status) {
            case 403:
              return new SyncError(
                'forbidden',
                'thunk',
                'You do not have access to this workspace',
                error
              );
            case 404:
              return new SyncError(
                'unknown_workspace',
                'thunk',
                'Workspace not found',
                error
              );
            case 410:
              return new SyncError(
                'object_not_found',
                'thunk',
                `Snapshot for workspace is gone`,
                error
              );
            case 500:
              return new SyncError(
                'network_error',
                'thunk',
                `Network error occurred: ${error.cause}`,
                error
              );
            default:
              return new SyncError(
                'generic_error',
                'thunk',
                `Generic error was thrown from the server: ${error.cause}, ${error.message}`,
                error
              );
          }
        } else {
          return throwGenericError('Generic error in getSnapshot', error);
        }
      })();
      return rejectWithValue(errorToReturn);
    }
  }
);

export interface UpdateObject {
  latestUpdateSequence: number;
}

const update = createAppAsyncThunk(
  'sync/update',
  async (patches: UpdatePatch[], { getState, rejectWithValue }) => {
    try {
      const store = getState() as RootState;
      const { sync } = store;
      const { workspace } = sync;

      if (!workspace) {
        throw new SyncError(
          'unknown_workspace',
          'thunk',
          `Error in update thunk: workspace is undefined when attempting to call update`,
          undefined,
          context
        );
      }
      const { id, latestUpdateSequence } = workspace;

      log(
        `Updating workspace ${id}`,
        {
          ...context,
          workspace,
          patches: sanitizePatches(patches),
        },
        'debug'
      );

      /**
       * posts the update
       */
      const response: AxiosResponse<Promise<UpdateObject>> =
        await syncAxios.put(`${getBaseUrl(store)}/${id}/update`, patches, {
          params: { sequenceNumber: latestUpdateSequence },
        });
      const data = await response.data;

      log(`Received update thunk response`, {
        ...context,
        response: sanitizeResponse(response),
      });
      return data;
    } catch (error: any) {
      const errorToReturn = ((): SyncError => {
        if (error && axios.isAxiosError(error) && error.response) {
          switch (error.response.status) {
            case 404:
              return new SyncError(
                'object_not_found',
                'thunk',
                `Error in update thunk: Workspace Id or Sequence Number not found when attempting to call update`,
                error,
                context
              );
            case 409:
              return new SyncError(
                'sequence_taken',
                'thunk',
                `Error in update thunk: Sequence number is already taken when attempting to call update`,
                error,
                context
              );
            default:
              return throwGenericError('Error in update thunk', error);
          }
        }
        return throwGenericError('Error in update thunk', error);
      })();
      return rejectWithValue(errorToReturn);
    }
  },
  {
    condition: (_, { getState }) => {
      const { sync } = getState() as RootState;
      const { enabled } = sync;
      return enabled;
    },
  }
);

const duplicate = createAppAsyncThunk(
  'sync/duplicate',
  async (
    atSequence: boolean | null | undefined,
    { getState, rejectWithValue }
  ) => {
    const store = getState() as RootState;
    const { sync, shaperHub } = store;
    const { workspace } = sync;
    const { locale, workspace: workspaceInfo } = shaperHub;

    if (!workspace || !workspaceInfo) {
      throw new SyncError(
        'unknown_workspace',
        'api',
        'Cannot duplicate workspace because there is no workspace Id set.'
      );
    }

    const { id, latestUpdateSequence } = workspace;
    const { name } = workspaceInfo;
    const path = 'path' in workspaceInfo ? workspaceInfo.path : '';

    const localeText = copyText[locale.language as keyof object];
    const workspaceName = name.replace(/copy \d+$/, '').trim();
    const url = (() => {
      if (atSequence) {
        return `${getBaseUrl(store)}/${id}/duplicate/${latestUpdateSequence}`;
      }
      return `${getBaseUrl(store)}/${id}/duplicate`;
    })();
    try {
      /**
       * duplicates the workspace
       */
      const response = await syncAxios.post(url, {
        path,
        namePrefix: `${workspaceName} ${localeText}`,
      });
      const data = await response.data;
      return data;
    } catch (error) {
      if (error instanceof SyncError) {
        return reject(error);
      }
      return rejectWithValue(
        new SyncError(
          'network_error',
          'thunk',
          `Network error occurred. ${JSON.stringify(error)}`
        )
      );
    }
  }
);

export type ValidatePatchesParams = {
  snapshot: Snapshot;
  jsonPatches: Operation[];
  patchesToSend: UpdatePatch[];
};
const doPatchValidation = createAppAsyncThunk(
  'sync/validatePatches',
  async (args: ValidatePatchesParams) => {
    const { snapshot, jsonPatches } = args;

    // wrapped in timeout so that the function runs in its own microtask
    // outside of pointer event
    const valid = await new Promise((resolve) =>
      setTimeout(() => resolve(validatePatches(snapshot, jsonPatches)), 0)
    );
    if (!valid) {
      throw new SyncError(
        'bad_patches',
        'listener',
        'Unable to convert immer to JSON patches',
        undefined,
        { jsonPatches, snapshot }
      );
    }
    return Promise.resolve(args);
  }
);

export {
  setSandboxMode,
  disconnect,
  open,
  create,
  getSnapshot,
  update,
  duplicate,
  reconnect,
  doPatchValidation,
};
