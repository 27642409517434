import React, { MouseEventHandler, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useAction } from '@/Actions/useAction';

// helpers
import classNames from 'classnames';
import { cancelEvent } from '@/Utility/events';
import evaluateExpression from '@/Helpers/EvaluateExpression';
import { handleKey } from '../../Utility/keyboard';

// slices
import {
  selectOptions,
  selectDisplayUnits,
  Options,
} from '@/Redux/Slices/SherpaContainerSlice';

// actions
import UpdateWorkspaceOptionsAction from '@/Actions/UpdateWorkspaceOptions';

// components
import Icon from '@/Styles/Icons/Icon';
import Switch from './Components/Switch';
import Check from './Components/Check';
import TranslationText from '../TranslationText/TranslationText';
import New from '../Badge/New';

type OptionItemProps = {
  icon?: string;
  active?: boolean;
  withComponents?: boolean;
  disabled?: boolean;
  onClick?: MouseEventHandler<HTMLDivElement>;
  dataCy?: string;
  children?: React.ReactNode;
};

export function ModeOptionItem(props: OptionItemProps) {
  const icon = props.icon ? <Icon>{props.icon}</Icon> : null;

  const itemCx = classNames(
    'mode-selection-menu--option',
    'mode-selection-menu--option-item',
    {
      active: props.active,
      'with-components': props.withComponents,
      'with-icon': icon,
      disabled: props.disabled,
    }
  );

  return (
    <div
      className={itemCx}
      onClick={!props.disabled ? props.onClick : undefined}
      data-cy={props.dataCy}
    >
      {icon}
      {props.children}
    </div>
  );
}

type OptionHeaderProps = {
  expanded: boolean;
  onActivate: () => void;
  dataCy: string;
  onToggleExpansion?: MouseEventHandler<HTMLDivElement>;
  icon: string;
  i18nKey: string;
  title: string;
  hideToggle: boolean;
  activated: boolean;
  children: React.ReactNode;
};

export function ModeOptionHeader(props: OptionHeaderProps) {
  const setCx = classNames(
    'mode-selection-menu--option',
    'mode-selection-menu--options-header',
    'with-icon',
    {
      expanded: props.expanded,
    }
  );

  function onActivate(event: boolean) {
    cancelEvent(event);
    props.onActivate();
  }

  return (
    <>
      <div className={setCx} data-cy={props.dataCy}>
        <div
          className='mode-selection-menu--options-icon'
          onClick={props.onToggleExpansion}
        >
          <Icon>{props.icon}</Icon>
        </div>
        <div
          className='mode-selection-menu--options-title'
          onClick={props.onToggleExpansion}
        >
          <TranslationText i18nKey={props.i18nKey}>
            {props.title}
          </TranslationText>
        </div>
        {!props.hideToggle && (
          <div className='mode-selection-menu--options-toggle'>
            <Switch active={props.activated} onToggle={onActivate} />
          </div>
        )}
      </div>

      {props.expanded && props.children && (
        <div className='mode-selection-menu--options-items'>
          {props.children}
        </div>
      )}
    </>
  );
}

type OptionItemInputProps = {
  defaultValue: string;
  suffix: string;
  onCommit: (newValue: string) => boolean | undefined;
  className?: string;
  calculate?: boolean;
  captureEvent?: boolean;
};

ModeOptionItem.Input = function (props: OptionItemInputProps) {
  const { defaultValue, suffix } = props;
  const inputRef = useRef<HTMLInputElement>(null);
  const shouldCalculate = 'calculate' in props;
  const displayUnits = useSelector(selectDisplayUnits);
  const captureEvent = 'captureEvent' in props && props.captureEvent !== false;
  const capture = captureEvent ? { onClick: cancelEvent } : null;

  function setValue(value: string) {
    if (inputRef.current) {
      inputRef.current.value = value;
    }
  }

  function getValue() {
    let value = inputRef.current?.value || '';
    if (shouldCalculate) {
      value = evaluateExpression(value, displayUnits).toString();
    }

    return value;
  }

  function resetValue() {
    setValue(defaultValue);
  }

  function onCommit() {
    const value = getValue();
    const result = props.onCommit?.(value);
    if (result === false) {
      resetValue();
    }
  }

  // handle changes
  function onKeyUp(event: React.KeyboardEvent) {
    handleKey(event, {
      capture: true,

      enter: () => inputRef.current?.blur(),
      esc: () => {
        resetValue();
        inputRef.current?.blur();
      },
    });
  }

  useEffect(resetValue, [defaultValue]);

  const className = classNames('mode-menu--input', props.className, {
    'with-suffix': suffix,
  });

  return (
    <div className={className} {...capture}>
      <input
        onKeyUp={onKeyUp}
        onBlur={onCommit}
        ref={inputRef}
        className='mode-menu--input--field'
      />
      {suffix && <div className='mode-menu--input--suffix'>{suffix}</div>}
    </div>
  );
};

type OptionItemCheckProps = {
  label: string;
  prop: keyof Options;
  children?: React.ReactNode;
  i18nKey: string;
  justifyChildren?: string;
  isNew?: boolean;
  disabled?: boolean;
  icon?: string;
  dataCy: string;
  onToggle?: (value: boolean) => void;
};

ModeOptionItem.Check = function (props: OptionItemCheckProps) {
  const {
    label,
    prop,
    children,
    i18nKey,
    justifyChildren,
    isNew,
    disabled = false,
    icon,
    dataCy,
  } = props;
  const withComponents = !!React.Children.toArray(children).length;

  // selectors
  const options = useSelector(selectOptions);

  // actions
  const updateOptionsAction = useAction(UpdateWorkspaceOptionsAction);

  // computed
  const checked = !!options[prop];

  // functions
  function onToggle(value: boolean) {
    if (!disabled) {
      updateOptionsAction.update({ [prop]: value });

      // if wanting to know
      if (props.onToggle) {
        props.onToggle(value);
      }
    }
  }

  function onClick(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    if (!disabled) {
      onToggle(!checked);
      cancelEvent(event);
    }
  }

  return (
    <ModeOptionItem
      active={checked}
      withComponents={withComponents}
      onClick={onClick}
      disabled={disabled}
      dataCy={dataCy}
    >
      <label>
        {isNew && (
          <span className='mode-selection-menu--option-item--new'>
            <New />
          </span>
        )}
        <TranslationText i18nKey={i18nKey}>{label}</TranslationText>
      </label>
      {children && (
        <div
          className={`mode-selection-menu--option-item--children ${
            justifyChildren || 'right'
          }`}
          data-cy={dataCy}
        >
          {children}
        </div>
      )}
      <div>
        <Check icon={icon} checked={checked} onToggle={onToggle} />
      </div>
    </ModeOptionItem>
  );
};

type OptionsKeys = keyof Options;

type OptionHeaderSectionProps = {
  title: string;
  prop: OptionsKeys;
  children?: React.ReactNode;
  i18nKey: string;
  dataCy: string;
  hideToggle?: boolean;
  icon: string;
  onToggle?: (newValue: boolean) => void;
};

ModeOptionHeader.Section = function (props: OptionHeaderSectionProps) {
  const { title, prop, children, i18nKey, dataCy, hideToggle = false } = props;

  // selectors
  const options = useSelector(selectOptions);

  // computed
  const activated = (!hideToggle && !!options[prop]) || hideToggle;
  const icon = `${props.icon}-${activated ? 'enabled' : 'disabled'}`;
  const expanded = activated;

  // actions
  const updateOptionsAction = useAction(UpdateWorkspaceOptionsAction);

  // handlers
  function onActivateSection() {
    const active = !options[prop];
    updateOptionsAction.update({ [prop]: active });

    if (props.onToggle) {
      props.onToggle(active);
    }
  }

  return (
    <ModeOptionHeader
      title={title}
      activated={activated}
      expanded={expanded}
      icon={icon}
      onActivate={onActivateSection}
      i18nKey={i18nKey}
      dataCy={dataCy}
      hideToggle={hideToggle}
    >
      {children}
    </ModeOptionHeader>
  );
};
