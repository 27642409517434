import { CanvasState } from '@/Redux/Slices/CanvasSlice';

export const SYNC_IDLE_TIMEOUT = 60000;

export type SyncStatus =
  | 'edit'
  | 'disconnected'
  | 'reconnecting'
  | 'pending'
  | 'error'
  | 'out-of-date'
  | 'invalid-workspace'
  | 'invalid-version'
  | 'bad-patches'
  | 'migrating';

export interface OpenOptions {
  workspaceId: string;
  isDuplicate?: boolean;
  blobId?: string;
}

export interface CreateOptions {
  path?: string;
  blobId?: string;
}

export interface DisconnectInterface {
  idleApp: boolean;
  createNewWorkspaceAfterDisconnect: boolean;
  openWorkspaceAfterDisconnect: {
    workspaceId: string;
    isDuplicate: boolean;
  };
}

export interface Snapshot {
  canvas: CanvasState | null;
}
