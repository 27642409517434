import { useSelector } from 'react-redux';

// consts
import { defaultSecretOptions } from '../../../defaults';
import { entitlements } from '@/Helpers/Entitlements';

// selectors
import { selectSelectedLine } from '@/Redux/Slices/LineToolSlice';
import {
  selectOptions,
  selectSecretOptions,
} from '@/Redux/Slices/SherpaContainerSlice';
import {
  selectFeatures,
  selectInsertPointModeOptions,
  selectIsInsertPointMode,
} from '@/Redux/Slices/UISlice';

// components
import Grid from '@/UILayer/Components/Grid';
import BoundingBox from '@/UILayer/Components/BoundingBox';
import SelectionNet from '@/UILayer/Components/SelectionNet';
import WorkspaceOrigin from '@/UILayer/Components/WorkspaceOrigin';
import AlignmentGuides from '@/UILayer/Components/AlignmentGuides';
import WorkspaceOriginLabels from '@/UILayer/Components/WorkspaceOriginLabel';
import Viewport from '@/UILayer/Components/Viewport';
import SvgGroups from '@/UILayer/Components/SvgGroups';
import CustomAnchor from '../../Components/CustomAnchor';
import InsertPointLocation from '@/UILayer/Components/InsertPointLocation/InsertPointLocation';
import SelectedLine from '@/UILayer/Components/SelectedLine';
import ActiveLine from '@/UILayer/Components/Polyline/ActiveLine';
import UIState from '@/UILayer/State/UIState';

type Props = {
  ui: UIState;
};

export default function DefaultUI(props: Props) {
  const { ui } = props;
  const options = useSelector(selectOptions);
  const { hasBounds, hasAlignmentGuides, hasSelectionNet } = ui;

  const insertPointOptions = useSelector(selectInsertPointModeOptions);
  const isInsertPointMode = useSelector(selectIsInsertPointMode);
  const selectedLine = useSelector(selectSelectedLine);
  const hasPositioning = selectFeatures(entitlements.PRECISION_POSITION);
  const hasGrid = selectFeatures(entitlements.GRID_VISIBILITY);
  const secretOptions = useSelector(selectSecretOptions);

  // computed
  let { showPositionLabels, showGrid, usePositioning, showCustomAnchor } =
    options;
  if (!usePositioning) {
    showPositionLabels = showGrid = showCustomAnchor = false;
  }

  const {
    guideUnselectedPathWidth,
    guideUnselectedPathColor,
    guideSelectedPathWidth,
    guideSelectedPathColor,
    objectUnselectedPathColor,
    objectUnselectedPathWidth,
    objectSelectedPathColor,
    objectSelectedPathWidth,
  } = {
    ...defaultSecretOptions,
    ...secretOptions,
  };

  const styles = `
      .ui-layer .svg-group.reference.selected .pathGroup2 path {
        stroke: #E27718;
        stroke-width: 12px;
        stroke-opacity: 0.12;
        vector-effect: non-scaling-stroke;
      }
      .ui-layer .svg-group.reference.selected path {
        stroke: #${guideSelectedPathColor};
        stroke-width: ${guideSelectedPathWidth}px;
      }
      .ui-layer .svg-group.reference path {
        stroke: #${guideUnselectedPathColor};
        stroke-width: ${guideUnselectedPathWidth}px;
      }
      .ui-layer .svg-group.design.selected path
      {
        stroke: #${objectSelectedPathColor};
        stroke-width: ${objectSelectedPathWidth}px;
      }
      .ui-layer .svg-group.design .pathGroup.selected > .basePath
      {
        stroke: #${objectSelectedPathColor};
        stroke-width: ${objectSelectedPathWidth}px;
      }
      .ui-layer .svg-group.design path {
        stroke: #${objectUnselectedPathColor};
        stroke-width: ${objectUnselectedPathWidth}px;
      }`;

  return (
    <div
      className={`ui-layer ${
        insertPointOptions?.type === 'mobile'
          ? 'is-mobile-insert-point-mode'
          : ''
      }`}
    >
      <style type='text/css'>{styles}</style>
      <div className='ui-layer--overlay'>
        {hasPositioning && showPositionLabels && hasBounds && (
          <WorkspaceOriginLabels ui={ui} boundingBox={ui.boundingBox!} />
        )}
      </div>

      <Viewport viewport={ui.viewport}>
        {hasGrid && showGrid && <Grid viewport={ui.viewport} />}
        {hasPositioning && usePositioning && (
          <WorkspaceOrigin {...props} hideGuides={!showPositionLabels} />
        )}
        {hasPositioning && showCustomAnchor && <CustomAnchor {...props} />}
        {<SvgGroups ui={ui} />}
        {hasAlignmentGuides && (
          <AlignmentGuides
            {...props}
            viewport={ui.viewport}
            guides={ui.alignmentGuides!}
          />
        )}
        {hasBounds && !selectedLine && (
          <BoundingBox
            {...props}
            {...ui.boundingBox!}
            aabb={ui.boundingBox!.aabb}
          />
        )}

        <ActiveLine ui={ui} />
        {selectedLine && <SelectedLine ui={ui} />}

        {/* hasSelectionNet ensures the net is available even if the remaining props may not be assigned */}
        {hasSelectionNet && <SelectionNet {...(ui.selectionNet as any)} />}
        {isInsertPointMode && <InsertPointLocation />}
      </Viewport>
    </div>
  );
}
