import React, { MouseEvent, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAction } from '@/Actions/useAction';
import { getEventAttributeValue } from '@/Utility/react';
import { debounce, trim } from 'lodash';
import { getSubscribeLink, getTrialLink } from '@/Utility/subscribe-link';

// selectors
import {
  selectSearchResults,
  selectSearchStatus,
  selectLastIconQuery,
} from '@/Redux/Slices/IconSearchSlice';
import {
  selectShaperSubscriptions,
  selectShaperMarketingUrl,
  selectUserIsVerified,
} from '@/Redux/Slices/ShaperHubSlice';

// actions
import UIFeatureAction from '@/Actions/UIFeature';
import IconSearchAction from '@/Actions/IconSearch';
import StartTrialAction from '@/Actions/StartTrial';

// components
import Icon from '@/Styles/Icons/Icon';
import FloatingPanel from '@/Components/FloatingPanel/FloatingPanel';
import InfiniteScroll from '@/Components/InfiniteScroll';
import Loading from '@/Components/Loading/Loading';
import { selectFeatures, selectIsMobile } from '@/Redux/Slices/UISlice';
import classNames from 'classnames';
import TranslationText from '../../../TranslationText/TranslationText';
import { useEffect } from 'react';
import { entitlements } from '@/Helpers/Entitlements';
import TextField from '@/Components/TextField/TextField';
import { selectFeatureFlags } from '@/Redux/Slices/FeatureFlagsSlice';

let position = 0;

export default function FindArtPanel() {
  const lastSearchQuery = useSelector(selectLastIconQuery);

  // state
  const [search, setSearch] = useState(lastSearchQuery?.query);
  const [page, setPage] = useState(lastSearchQuery?.page || 1);
  const [scrollPosition, setScrollPosition] = useState(0);

  // selectors
  const results = useSelector(selectSearchResults);
  const status = useSelector(selectSearchStatus);
  const featureFlags = useSelector(selectFeatureFlags);

  const { isTrial, isSubscriber, isExpired } = useSelector(
    selectShaperSubscriptions
  );
  const isLite = !isTrial && !isSubscriber;
  const userIsVerified = useSelector(selectUserIsVerified);
  const shaperMarketingUrl = useSelector(selectShaperMarketingUrl);

  // actions
  const iconSearchAction = useAction(IconSearchAction);
  const uiFeatureAction = useAction(UIFeatureAction);
  const startTrialAction = useAction(StartTrialAction);

  // computed
  const hasResults = results?.length > 0;
  const isSearching = ['pending'].includes(status);
  const disableSearchMore = isSearching;
  const hasFullFeature =
    featureFlags['release-studio-free'] ||
    selectFeatures(entitlements.FIND_ART);
  const isMobile = useSelector(selectIsMobile);

  const filteredResults = hasFullFeature
    ? results
    : results.filter((r) => r.license_description === 'public-domain');
  const upgradeResults = results.filter(
    (r) => r.license_description !== 'public-domain'
  );
  const debounceStart = debounce(() => {
    startTrialAction.start();
  }, 1000);

  // handlers
  function onClose() {
    iconSearchAction.setScrollPosition(scrollPosition);
    uiFeatureAction.toggleFindArt(false);
  }

  function onReset() {
    setPage(1);
  }

  function onSearch(str?: string) {
    const phrase = trim(str);

    if (!phrase || phrase === lastSearchQuery?.query) {
      return;
    }

    // request new icons
    iconSearchAction.clear();
    iconSearchAction.search(phrase, page);
    setSearch(phrase);
  }

  // appends an icon to the view
  function onAddIcon(event: MouseEvent) {
    const id = getEventAttributeValue(event, 'data-id');
    const icon = results.find((result) => result.id === id);
    if (!icon) {
      return;
    }

    // add it
    iconSearchAction.add(icon, search);
    iconSearchAction.setScrollPosition(scrollPosition);
    // also open the properties panel
    uiFeatureAction.toggleEditSelectionProperties(true);

    onClose();
  }

  function onFindMore() {
    const nextPage = page + 1;
    setPage(page + 1);

    // search for more
    iconSearchAction.search(search, nextPage, true);
  }

  function openLink() {
    if (isLite || (isSubscriber && isExpired)) {
      if (userIsVerified) {
        debounceStart();
      } else {
        const trialPath = getTrialLink();
        window.open(`${shaperMarketingUrl}/${trialPath}`, '_self');
      }
    } else if (isTrial) {
      const subscribePath = getSubscribeLink();
      window.open(`${shaperMarketingUrl}/${subscribePath}`, '_self');
    }
  }

  function updateScrollPosition(value: number) {
    setScrollPosition(value);
    position = value;
  }

  const findArtFormCx = classNames('find-art--form', {
    search: isSearching,
  });

  const findArtResultsCx = classNames('find-art--results', {
    hasResults: filteredResults,
  });

  useEffect(() => {
    return () => {
      iconSearchAction.setScrollPosition(position);
      position = 0;
    };
  }, []);

  function renderContents() {
    return (
      <>
        {filteredResults.map((result) => (
          <div className='find-art--result--container' key={result.id}>
            <canvas width={1} height={1} className='find-art--result--sizer' />
            <div
              className='find-art--result'
              data-id={result.id}
              dangerouslySetInnerHTML={{ __html: result.iconSVG }}
              onClick={onAddIcon}
            />
          </div>
        ))}
        {!hasFullFeature && (
          <>
            <div className='find-art--result--container upgrade'>
              <div className='find-art--cta'>
                <TranslationText i18nKey='public-domain'>
                  You've reached the end of public domain designs available in
                  Studio Lite. For commercially licensed options, start your
                  free trial.
                </TranslationText>
              </div>
              <div className='find-art--subscribe' onClick={openLink}>
                <div data-cy='find-art-cta'>
                  {isLite ? (
                    <TranslationText i18nKey='art-start-trial'>
                      Start Trial for More Designs
                    </TranslationText>
                  ) : (
                    <TranslationText i18nKey='art-subscribe'>
                      Subscribe for More Designs
                    </TranslationText>
                  )}
                </div>
                <Icon icon='chevron-right' />
              </div>
            </div>
            {upgradeResults.map((result) => (
              <div className='find-art--result--container' key={result.id}>
                <canvas
                  width={1}
                  height={1}
                  className='find-art--result--sizer'
                />
                <div
                  className='find-art--result upgrade'
                  data-id={result.id}
                  dangerouslySetInnerHTML={{ __html: result.iconSVG }}
                />
                <Icon icon='premium' className='find-art--result--premium' />
              </div>
            ))}
          </>
        )}
      </>
    );
  }

  function renderResults() {
    return isMobile ? (
      <div className={findArtResultsCx}>
        <InfiniteScroll
          className='find-art--results--grid'
          disabled={disableSearchMore}
          onReachEnd={onFindMore}
          scrollPosition={lastSearchQuery?.scrollPosition}
          onScroll={updateScrollPosition}
        >
          {renderContents()}
        </InfiniteScroll>
      </div>
    ) : (
      <InfiniteScroll
        className={findArtResultsCx}
        disabled={disableSearchMore}
        onReachEnd={onFindMore}
        scrollPosition={lastSearchQuery?.scrollPosition}
        onScroll={updateScrollPosition}
      >
        <div className='find-art--results--grid'>{renderContents()}</div>
      </InfiniteScroll>
    );
  }

  return (
    <>
      <FloatingPanel
        className='properties-panel--find-art'
        container
        edge='left'
        onClose={onClose}
        hideCloseOnMobile
        hideMobilePullBar
        dataCy='find-art-panel'
      >
        <div className={findArtFormCx}>
          <div className='find-art--search'>
            <div className='find-art--close-button' onClick={onClose}>
              <Icon icon='close' />
            </div>
            <SearchInput
              defaultValue={search}
              onSearch={onSearch}
              onReset={onReset}
            />
          </div>

          {hasResults && renderResults()}

          {isSearching && (
            <div className='find-art--instructions'>
              <Loading />
            </div>
          )}

          {!isSearching && !hasResults && (
            <div className='find-art--instructions'>
              <TranslationText i18nKey='art-instructions'>
                Type in a search term to find art
              </TranslationText>
            </div>
          )}
        </div>
      </FloatingPanel>
      <div className='find-art--overlay' />
    </>
  );
}

type SearchInputProps = {
  defaultValue: string;
  onSearch: (value: string) => void;
  onReset: () => void;
};

function SearchInput(props: SearchInputProps) {
  const { defaultValue } = props;

  const [value, setValue] = useState<string>(defaultValue);
  const [newValue, setNewValue] = useState<string>(defaultValue);
  const [focus, setFocus] = useState(false);

  const onChange = (v: string) => {
    setNewValue(v);
  };

  const onSubmit = (v: string) => {
    props.onReset();
    onChange(v);
    props.onSearch(v);
    setValue(v);
    setFocus(false);
  };

  const onSearch = () => {
    props.onReset();
    props.onSearch(newValue);
  };

  const onBlur = () => {
    setFocus(false);
  };

  const onClick = () => {
    setFocus(true);
  };

  useEffect(() => {
    setValue(defaultValue);
    setNewValue(defaultValue);
  }, [defaultValue]);

  const textfieldCx = classNames('find-art--search-input--textfield', {
    focused: focus,
  });

  return (
    <div className='find-art--search-input'>
      <div className='find-art--search-input--search-icon'>
        <Icon>search</Icon>
      </div>
      <TextField
        className={textfieldCx}
        value={value}
        defaultValue={defaultValue}
        dataCy='find-art-search'
        onChange={onChange}
        onSubmit={onSubmit}
        onBlur={onBlur}
        onClick={onClick}
        submitOnEnter
      />
      <div className='find-art--search-input--search-hint'>
        <SearchHint active={newValue !== value} onClick={onSearch} />
      </div>
    </div>
  );
}

type SearchHintProps = {
  active: boolean;
  onClick: () => void;
};
function SearchHint(props: SearchHintProps) {
  const { active } = props;

  const onClick = () => {
    if (props.onClick) {
      props.onClick();
    }
  };

  return (
    <div
      className={`find-art--search-hint ${active && 'ready'}`}
      data-cy='find-art-search-button'
      onClick={onClick}
    >
      <Icon icon='enter-key' />
      <div>
        <TranslationText i18nKey='search'>Search</TranslationText>
      </div>
    </div>
  );
}
