import { createSlice, Middleware, PayloadAction } from '@reduxjs/toolkit';
import { defaultDocumentName } from '@/defaults';
import { defaultSecretOptions, unitDefaults } from '../../defaults';
import { isBoolean, isNumber } from 'lodash';
import { save, restore } from '../../Helpers/UserPreferences';
import { setCanvasCustomAnchor } from '@/CanvasContainer/CanvasActions';
import {
  DisplayUnits,
  Modal,
  ModalTriggerTypes,
} from '../../@types/shaper-types';
import { AppDispatch, RootState } from '../store';

// session storage for the actively worked on workspace
const SESSION_WORKSPACE_ID = 'shaper:session-workspace-id';

export interface SecretOptions {
  gridSnappingThreshold: number;
  smartSnappingThreshold: number;
  guideLineWidth: number;
  guideLineColor: string;
  guideUnselectedPathWidth: number;
  guideUnselectedPathColor: string;
  guideUnselectedHoverPathWidth: number;
  guideUnselectedHoverPathColor: string;
  guideGlowPathWidth: number;
  guideGlowPathColor: string;
  guideSelectedPathWidth: number;
  guideSelectedPathColor: string;
  guideSelectedHoverPathWidth: number;
  guideSelectedHoverPathColor: string;
  objectUnselectedPathWidth: number;
  objectUnselectedPathColor: string;
  objectUnselectedHoverPathWidth: number;
  objectUnselectedHoverPathColor: string;
  objectSelectedPathWidth: number;
  objectSelectedPathColor: string;
  objectSelectedHoverPathWidth: number;
  objectSelectedHoverPathColor: string;
  positionColor: string;
  positionStrokeWidth: number;
  positionDashWidth: number;
  positionGapWidth: number;
  showWorkspaceSettings: boolean;
  language: string;
}

export interface Options {
  useSnapping: boolean;
  alignToObjecst: boolean;
  alignToGrid: boolean;
  usePositioning: boolean;
  showGrid: boolean;
  showPositionLabels: boolean;
  showCustomAnchor: boolean;
  useCutPathDisplay: boolean;
  useCutPaths: boolean;
  showApplicationMenu?: boolean;
}

export interface SherpaContainerSlice {
  toolEnabled: boolean;
  shaperHubLoggedIn: boolean;
  documentName: string;
  options: Options;
  displayUnits: DisplayUnits;
  secretOptions: SecretOptions;
  modal: Modal | null;
  sherpaInitialized: boolean;
  loading: boolean;
}

export const initialSherpaState: SherpaContainerSlice = {
  ...restore<{
    displayUnits: DisplayUnits;
  }>({
    displayUnits: {
      default: 'in',
      validate: (val: string) => ['in', 'mm'].includes(val),
    },
  }),
  options: {
    ...restore<Options>({
      useSnapping: true,
      alignToObjects: false,
      alignToGrid: false,

      usePositioning: false,
      showGrid: false,
      showPositionLabels: false,
      showCustomAnchor: false,

      useCutPathDisplay: false,
      useCutPaths: false,
    }),
  },
  toolEnabled: false,
  shaperHubLoggedIn: false,
  documentName: defaultDocumentName,
  secretOptions: defaultSecretOptions as SecretOptions,
  modal: null,
  sherpaInitialized: false,
  loading: false,
};

export const slice = createSlice({
  name: 'sherpaContainer',
  initialState: initialSherpaState,
  reducers: {
    // Redux Toolkit allows us to write "mutating" logic in reducers. It doesn't actually mutate the state because it uses the immer library, which detects changes to a "draft state" and produces a brand new immutable state based off those changes

    enableTool: (state) => {
      state.toolEnabled = true;
    },

    disableTool: (state) => {
      state.toolEnabled = false;
    },

    updateOptions: (state, action: PayloadAction<Partial<Options>>) => {
      state.options = { ...state.options, ...action.payload };
      save(state.options);
    },

    updateCustomAnchorOption: (state, action) => {
      if (state.options.showCustomAnchor !== action.payload) {
        state.options.showCustomAnchor = action.payload;
        save(state.options);
      }
    },

    setDocumentName: (state, action: PayloadAction<string>) => {
      state.documentName = action.payload;
    },

    updateShaperHubStatus: (state, action: PayloadAction<boolean>) => {
      state.shaperHubLoggedIn = action.payload;
    },

    setDisplayUnits: (
      state,
      action: PayloadAction<{ newDisplayUnits: DisplayUnits }>
    ) => {
      state.displayUnits = action.payload.newDisplayUnits;
      save({ displayUnits: state.displayUnits });
    },

    updateSecretOptions: (
      state,
      action: PayloadAction<Partial<SecretOptions>>
    ) => {
      state.secretOptions = { ...state.secretOptions, ...action.payload };
    },

    setModal: {
      reducer: (state, action: PayloadAction<Modal>) => {
        state.modal = action.payload;
      },
      prepare: (payload: Modal, trigger?: ModalTriggerTypes) => {
        return { payload, meta: { trigger } };
      },
    },

    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },

    setSherpaInitialized: (state) => {
      state.sherpaInitialized = true;
    },

    setSessionWorkspaceID: (
      state,
      action: PayloadAction<string | null | undefined>
    ) => {
      const { payload: id } = action;
      sessionStorage?.[id ? 'setItem' : 'removeItem'](
        SESSION_WORKSPACE_ID,
        id as string
      );
    },
  },
});

export const {
  enableTool,
  disableTool,
  updateShaperHubStatus,
  setDocumentName,
  setDisplayUnits,
  updateSecretOptions,
  setModal,
  setLoading,
  setSherpaInitialized,
  setSessionWorkspaceID,
  updateOptions,
  updateCustomAnchorOption,
} = slice.actions;

export const middleware: Middleware =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    // this update happens when a user toggles the option in the
    // design mode menu
    if (updateCustomAnchorOption.match(action)) {
      (dispatch as AppDispatch)(setCanvasCustomAnchor(action.payload));
    }

    next(action);
  };

//State.Name.Key...
export const selectDocumentName = (state: RootState) =>
  state.sherpaContainer.documentName;
export const selectToolEnabled = (state: RootState) =>
  state.sherpaContainer.toolEnabled;
export const selectDisplayUnits = (state: RootState) =>
  state.sherpaContainer.displayUnits || 'in';
export const selectOptions = (state: RootState) => {
  const displayUnits = selectDisplayUnits(state);
  const { ui } = state;
  const options = { ...(state.sherpaContainer.options || {}) };

  // disable custom anchors when not allowed
  if (ui.featureMode !== 'full' || !options.usePositioning) {
    options.showCustomAnchor = false;
  }
  return { ...state.sherpaContainer.options, displayUnits };
};
export const selectSecretOptions = (state: RootState) =>
  state.sherpaContainer.secretOptions;
export const selectLoading = (state: RootState) => {
  return state.sherpaContainer.loading;
};

export const selectSessionWorkspaceID = () =>
  sessionStorage.getItem(SESSION_WORKSPACE_ID);

export const selectToFormattedDisplayUnitValue = (state: RootState) => {
  return (
    input: any,
    options: { preformatted?: boolean; includeUnit?: boolean } = {}
  ) => {
    const units = selectDisplayUnits(state);
    const asInches = units === 'in';

    // find the number of decimals to use
    const decimals = asInches ? 3 : 2;

    // get the value to use
    let value = isNaN(input) ? parseFloat(input) : input;
    if (!options.preformatted && asInches) {
      value /= unitDefaults.metric;
    }

    // create the display string
    let display = parseFloat(value).toFixed(decimals);

    // do they want the units added
    if (options.includeUnit) {
      display += ` ${units}`;
    }

    return display;
  };
};

// TODO: where do we actually get the unit conversion
export const selectToDisplayUnits =
  (state: RootState) =>
  (input: number, ...args: unknown[]) => {
    let asData;
    let decimals;

    // check for values
    for (let arg of args) {
      if (isNumber(arg)) {
        decimals = arg;
      } else if (isBoolean(arg)) {
        asData = arg;
      }
    }

    const { displayUnits } = state.sherpaContainer;
    const asInches = displayUnits === 'in';

    // get the value to use
    let value = input;

    // TODO: how do we actually want to confirm this
    // exponential?
    if (Math.abs(value) < 0.001) {
      value = 0;
    }

    // convert to inches
    if (asInches) {
      value /= unitDefaults.metric;
    }

    const valueString = (() => {
      if (decimals && !isNaN(decimals)) {
        return value.toPrecision(decimals + 1);
      }
      return value.toString();
    })();

    // round the number, if needed

    // gets the label to use
    const label = `${valueString} ${displayUnits}`;

    // check for data
    if (!asData) {
      return label;
    }

    // return extra data
    return {
      label,
      value,
      unit: displayUnits,
    };
  };

export const selectModal = (state: RootState) => state.sherpaContainer.modal;

export const actions = slice.actions;
export default slice.reducer;
