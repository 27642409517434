//Wrapper for JS port of clipper
//Clipper can be accessed synchronously or asynchronously using a web worker
//For synchronous access, use the exported ClipperOps, which is an instance of ClipperOpsSingleton
//For asynchronous access, use ClipperOpsWorkerProxy, which traps all ClipperOps methods calls, posts them to a webworker instance of ClipperOps, and returns a promise that will be fulfilled when the web worker completes the operation

import { Path } from '@shapertools/sherpa-svg-generator/Path';
import { Point } from '@shapertools/sherpa-svg-generator/Point';
import '@/Clipper/clipper.js';
import ClipperWorker from './ClipperOps.worker?worker';

const ClipperLib = 'ClipperLib' in window ? window.ClipperLib : undefined;

export const clipperScale = 10000;

export class ClipperPoint {
  X: number;
  Y: number;

  constructor(X: number, Y: number) {
    this.X = X;
    this.Y = Y;
  }
}

export class ClipperPath {
  closed: boolean;
  points: ClipperPoint[];

  constructor(closed: boolean, points: ClipperPoint[]) {
    this.closed = closed;
    this.points = points;
  }
}

class ClipperOpsSingleton {
  cpr: any;
  co: any;
  clipperScale: number;
  clipperCleanDelta: number;

  constructor() {
    if (ClipperLib) {
      this.cpr = new ClipperLib.Clipper();
      this.co = new ClipperLib.ClipperOffset();
    }
    this.clipperScale = clipperScale;
    this.clipperCleanDelta = 1.415;
  }

  toShaperPath(path: ClipperPath): Path {
    return new Path({
      closed: path.closed,
      points: path.points.map(
        (pt) => new Point(pt.X / clipperScale, pt.Y / clipperScale)
      ),
    });
  }

  toShaperPoint(pt: ClipperPoint): Point {
    return new Point(pt.X, pt.Y);
  }

  simplifyClipperPolygon(clipperPath: ClipperPath): ClipperPath[] {
    const simplifiedPolysPoints = ClipperLib.Clipper.SimplifyPolygon(
      ClipperLib.Clipper.CleanPolygon(
        clipperPath.points,
        this.clipperCleanDelta
      ),
      ClipperLib.PolyFillType.pftEvenOdd
    );

    return simplifiedPolysPoints.map((spp: ClipperPoint) => ({
      closed: true,
      points: spp,
    }));
  }

  offsetClipperPath(
    clipperPoints: ClipperPoint[],
    closed: boolean,
    offset: number,
    roundCorners = false
  ): {
    closedOffsetPaths: ClipperPoint[][];
    openOffsetPaths: ClipperPoint[][];
  } {
    const joinType = roundCorners
      ? ClipperLib.JoinType.jtRound
      : ClipperLib.JoinType.jtMiter;

    this.co.Clear();
    const endType = closed
      ? ClipperLib.EndType.etClosedPolygon
      : ClipperLib.EndType.etOpenRound;

    this.co.AddPath(clipperPoints, joinType, endType);

    const offsetPolytree = new ClipperLib.PolyTree();

    this.co.Execute(offsetPolytree, offset * this.clipperScale);

    const closedOffsetPaths =
      ClipperLib.Clipper.ClosedPathsFromPolyTree(offsetPolytree);

    const openOffsetPaths =
      ClipperLib.Clipper.OpenPathsFromPolyTree(offsetPolytree);

    return { closedOffsetPaths, openOffsetPaths };
  }

  //Return offset simplepolygons given polygon input
  offsetClipperPolygons(
    clipperPolys: ClipperPath[],
    offset: number,
    roundCorners = false
  ) {
    const joinType = roundCorners
      ? ClipperLib.JoinType.jtRound
      : ClipperLib.JoinType.jtMiter;
    const endType = ClipperLib.EndType.etClosedPolygon;

    this.co.Clear();

    clipperPolys.forEach((clipperPoly) => {
      this.co.AddPath(clipperPoly.points, joinType, endType);
    });

    const offsetPolytree = new ClipperLib.PolyTree();

    this.co.Execute(offsetPolytree, offset * this.clipperScale);

    return this.clipperPolytreeToSimplePolygon(offsetPolytree);
  }

  clipperPathsBooleanPolyTree(
    clipperPathsA: ClipperPath[],
    clipperPathsB: ClipperPath[],
    opType: string,
    simplifyPolys: boolean
  ) {
    this.cpr.Clear();
    let ctType, ctError;
    switch (opType) {
      case 'difference':
        ctType = ClipperLib.ClipType.ctDifference;
        ctError = 'SimplePolygon difference failed';
        break;

      case 'union':
        ctType = ClipperLib.ClipType.ctUnion;
        ctError = 'SimplePolygon union failed';
        break;

      case 'intersection':
        ctType = ClipperLib.ClipType.ctIntersection;
        ctError = 'SimplePolygon intersection failed';
        break;

      default:
        throw new Error(`Invalid clipper operation:${opType}`);
    }
    clipperPathsA.forEach((cp) => {
      try {
        // console.log(`Boolean points A ${cp.points.length}`);
        this.cpr.AddPath(cp.points, ClipperLib.PolyType.ptSubject, cp.closed);
      } catch (err) {
        console.error(`Clipper error: ${err}`);
      }
    });

    clipperPathsB.forEach((cp) => {
      try {
        // console.log(`Boolean points B ${cp.points.length}`);
        this.cpr.AddPath(cp.points, ClipperLib.PolyType.ptClip, cp.closed);
      } catch (err) {
        console.error(`Clipper error: ${err}`);
      }
    });

    if (simplifyPolys) {
      this.cpr.StrictlySimple = true;
    }
    const solution_polytree = new ClipperLib.PolyTree();
    const succeeded = this.cpr.Execute(
      ctType,
      solution_polytree,
      ClipperLib.PolyFillType.pftEvenOdd,
      ClipperLib.PolyFillType.pftEvenOdd
    );

    if (!succeeded) {
      console.log(ctError);
    }

    return solution_polytree;
  }

  clipperPolytreeToSimplePolygon(polyTree: any): {
    outerClipperPath: {
      points: any;
      closed: boolean;
    };
    holeClipperPaths: any;
  }[] {
    //Now get all outer polygons and associated holes
    const clipperPathsResults = [] as any[];
    let currentNode = polyTree.GetFirst();
    while (currentNode !== null) {
      if (!currentNode.IsOpen) {
        const currentNodeOuterPath = {
          points: currentNode.Contour(),
          closed: true,
        };
        const currentNodeHolePaths = currentNode.Childs().map((child: any) => ({
          points: child.Contour(),
          closed: !child.IsOpen,
        }));

        const result = {
          outerClipperPath: currentNodeOuterPath,
          holeClipperPaths: currentNodeHolePaths,
        };

        clipperPathsResults.push(result);
      }
      do {
        currentNode = currentNode.GetNext();
      } while (currentNode !== null && currentNode.IsHole());
    }
    return clipperPathsResults;
  }

  //Returns array of {outerClipperPath, holeClipperPaths} objects, which can be converted to SimplePolygons for shapeshifter
  clipperPathsBoolean(
    clipperPathsA: ClipperPath[],
    clipperPathsB: ClipperPath[],
    opType: string,
    simplifyPolys: boolean = false
  ) {
    const polyTree = this.clipperPathsBooleanPolyTree(
      clipperPathsA,
      clipperPathsB,
      opType,
      simplifyPolys
    );

    return this.clipperPolytreeToSimplePolygon(polyTree);
  }

  //Subtract B from A, returns array of array of clipperPaths representing paths and holes
  clipperPathsDifference(
    clipperPathsA: ClipperPath[],
    clipperPathsB: ClipperPath[],
    simplifyPolys: boolean = false
  ) {
    return this.clipperPathsBoolean(
      clipperPathsA,
      clipperPathsB,
      'difference',
      simplifyPolys
    );
  }

  //Intersect A and B, returns array of array of clipperPaths representing paths and holes
  clipperPathsIntersection(
    clipperPathsA: ClipperPath[],
    clipperPathsB: ClipperPath[]
  ) {
    return this.clipperPathsBoolean(
      clipperPathsA,
      clipperPathsB,
      'intersection'
    );
  }

  //Union A and B, returns array of array of clipperPaths representing paths and holes
  clipperPathsUnion(
    clipperPathsA: ClipperPath[],
    clipperPathsB: ClipperPath[],
    simplifyPolys = false
  ) {
    return this.clipperPathsBoolean(
      clipperPathsA,
      clipperPathsB,
      'union',
      simplifyPolys
    );
  }

  cleanClipperPolygon(clipperPolygonPaths: ClipperPoint[]): ClipperPoint[] {
    return ClipperLib.Clipper.CleanPolygon(clipperPolygonPaths);
  }

  clipperMinkowskiSum(
    patternClipperPath: ClipperPath,
    targetClipperPath: ClipperPath
  ) {
    //{closed, points}
    return ClipperLib.Clipper.MinkowskiSum(
      patternClipperPath.points,
      targetClipperPath.points,
      targetClipperPath.closed
    );
  }

  clipperMinkowskiToolpath(targetClipperPath: ClipperPath, toolDiaMm: number) {
    const toolRadius = (toolDiaMm / 2) * this.clipperScale;
    const patternSize = 4;
    const angleStep = (2 * Math.PI) / patternSize;
    const toolPatternPts = new Array(patternSize).fill(0).map(
      (_v, idx) =>
        ({
          X: toolRadius * Math.cos(idx * angleStep),
          Y: toolRadius * Math.sin(idx * angleStep),
        } as ClipperPoint)
    );
    const patternClipperPath = {
      points: toolPatternPts,
      closed: true,
    } as ClipperPath;
    return this.clipperMinkowskiSum(patternClipperPath, targetClipperPath);
  }
}

const ClipperOps = new ClipperOpsSingleton();

interface WorkerPool {
  worker: Worker;
  idx: number;
  available: boolean;
}

//ClipperOpsSupervisor will create, terminate, and respond to call a ClipperOps web worker.
class ClipperOpsSupervisor {
  workerPool: WorkerPool[] = [];
  id: number;
  resolvers: any;
  rejectors: any;
  workerCount: number;
  nextWorkerIdx: number;

  constructor() {
    this.id = 0;
    this.resolvers = {};
    this.rejectors = {};
    this.workerCount = Math.max((navigator.hardwareConcurrency ?? 3) - 2, 2);
    this.nextWorkerIdx = 0;
    this.terminateAndReset();
  }

  getId = () => this.id++;

  terminateAndReset = () => {
    //Terminate current worker and clear callback arrays
    if (this.workerPool !== undefined) {
      this.workerPool.forEach((w: { worker: Worker }) => w.worker.terminate());
      this.resolvers = {};
      this.rejectors = {};
      this.nextWorkerIdx = 0;
    }

    this.workerPool = new Array(this.workerCount).fill({ available: true });
    //Now init new workers
    this.workerPool.forEach((status, idx) => {
      const worker = new ClipperWorker();
      this.workerPool[idx] = { ...status, idx, worker };

      worker.onmessage = (msg) => {
        const { id, result, error } = msg.data;

        //Access rejector or resolver by returned id and call it to pass data to the promise chain
        if (error !== undefined) {
          console.log(error);
          this.rejectors[id](error);
        }
        this.workerPool[idx].available = true;
        this.resolvers[id](result);

        delete this.resolvers[id];
        delete this.rejectors[id];
      };
    });
  };

  selectNextWorker() {
    let workerCandidate = this.workerPool.find((w) => w.available);
    if (workerCandidate === undefined) {
      //Round robin if all workers are busy
      workerCandidate = this.workerPool[this.nextWorkerIdx++];
      if (this.nextWorkerIdx >= this.workerCount) {
        this.nextWorkerIdx = 0;
      }
    }
    return workerCandidate;
  }

  postWorkerMessage(workerMethod: string, args: any) {
    if (this.workerPool !== undefined) {
      //First get an id so we can track responses from the worker
      const id = this.getId();
      const workerObj = this.selectNextWorker();

      // console.log(`ClipperOpsSupervisor - posting worker: ${workerObj.idx} id:${id} ${workerMethod}`);
      workerObj.worker.postMessage({ id, type: workerMethod, args });
      workerObj.available = false;

      //Return a promise when this function is called
      //When the parent sets this promise's resolve or reject properties (via a .then or .catch), store them with the id in the target so we can call these functions (really callback functions) when the worker completes.
      return new Promise((resolve, reject) => {
        this.resolvers[id] = resolve;
        this.rejectors[id] = reject;
      });
    }
    throw new Error('Worker not initialized');
  }
}

const ClipperOpsWorkerProxy = new Proxy(new ClipperOpsSupervisor(), {
  get: (target: any, prop) => {
    //ClipperOpsSupervisor (conveniently) does not have any property names in common with ClipperOps
    //We can pass this proxy off as ClipperOps. Any properties not part of the Supervisor are assumed to be ClipperOps properties and are dispatched to the webworker.
    if (!Reflect.ownKeys(target).includes(prop)) {
      //This is a call to a real clipperOps method, so pass it to the webworker

      //Return a function that passes the function name, function args, and the id to the worker and returns a promise
      //The code calling ClipperOpsWorkerProxy.method(args) will receive this function back and then invoke it with the args that it wants for ClipperOps
      return (...args: any) => target.postWorkerMessage(prop, args);
    }

    //Return the requested Supervisor property
    if (typeof target[prop] === 'function') {
      return target[prop].bind(target);
    }
    return target[prop];
  },
});

export { ClipperOpsWorkerProxy, ClipperOps };
