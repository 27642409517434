// @ts-nocheck
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getCanvasSVG } from '@/Geometry/CanvasOps';
import axios from 'axios';
import {
  getAccessToken,
  login,
  logout,
} from '@shapertools/client-authentication';
import { defaultLocale, getChargebeeSiteForLocale } from '../defaults';
import { FEATURE_MODES } from '../Constants/Subscriptions';
import { defaultSubscriptionOptions, apiTimeouts } from '../defaults';
import { addAttributeToUser } from '../Utility/userflow';
import {
  localhostDomainRegex,
  studioCookieStorage,
} from '../Utility/token-storage';
import { CustomErrors } from '@/Constants/Errors';
import {
  Subscription,
  SubscriptionTier,
  UserspaceExternalItemFileObject,
  UserspaceFileObject,
} from '@/@types/shaper-types';
import { getDeveloperSettings } from '@/Utility/developer-settings';
import { selectFeatureFlags } from '@/Redux/Slices/FeatureFlagsSlice';

//SH Axios stores current bearer token and uses interceptor to automatically refresh
const shaperHubAxios = axios.create({
  timeout: apiTimeouts.shaperHub,
  headers: {
    common: {
      'X-ApiVersion': '3.0.0',
    },
  },
});

export const getShaperAccessToken = async () => {
  const authURL = import.meta.env.VITE_SHAPER_URL_AUTH;
  if (localhostDomainRegex.test(window.location.host)) {
    const token = await getAccessToken({
      authServiceUrl: authURL,
      tokenStorage: studioCookieStorage(window),
    });
    return token;
  }
  const token = await getAccessToken({
    authServiceUrl: authURL,
  });
  return token;
};

shaperHubAxios.interceptors.request.use(async (request) => {
  try {
    if (localhostDomainRegex.test(window.location.host)) {
      const token = await getShaperAccessToken();
      request.headers.Authorization = `Bearer ${token}`;
      return request;
    }
    const token = await getShaperAccessToken();
    request.headers.Authorization = `Bearer ${token}`;
    return request;
  } catch (err) {
    console.log(err);
    return request;
  }
});

export const updateServerSHA = createAsyncThunk(
  'shaperhub/getServerSHA',
  async () => {
    const response = await fetch('/version.json');
    const { commit } = await response.json();
    return { commit };
  }
);

//Developer override for subscription entitlements
//set to undefined to disable override or desired feature mode for development
const defaultFeatureMode = FEATURE_MODES.DEMO;

export interface GetShaperSubscriptionsResponse {
  featureMode: SubscriptionTier;
  subscriptions: Subscription;
}

export const getShaperSubscriptions = createAsyncThunk(
  'shaperhub/getShaperSubscriptions',
  async (arg, { getState, fulfillWithValue }) => {
    const { entitlementsURL } = getState().shaperHub;

    // local dev
    if (localhostDomainRegex.test(window.location.host)) {
      return {
        featureMode: FEATURE_MODES.FULL,
        subscriptions: {
          isTrial: false,
          isSubscriber: true,
          isExpired: false,
          daysLeft: 1000,
        },
      };
    }

    return shaperHubAxios
      .get(`${entitlementsURL}/batch?entitlements=studioFull`)
      .then((response) => {
        const resp = response.data;
        const now = new Date();
        const timed = resp?.studioFull?.filter(
          (info) => info.accessType === 'timed'
        );
        const subscriptions = timed.filter(
          (info) => info.state === 'subscription'
        );
        const endDateWithGracePeriod = (info) =>
          new Date(new Date(info.endDate).getTime() + info.gracePeriod * 1000);
        const endedSubscriptions = subscriptions.filter(
          (info) => endDateWithGracePeriod(info) <= now
        );
        const activeOrFutureSubscriptions = subscriptions.filter(
          (info) => endDateWithGracePeriod(info) > now
        );
        const trials = timed.filter((info) => info.state === 'trial');
        const endedTrials = trials.filter(
          (info) => endDateWithGracePeriod(info) <= now
        );
        const activeOrFutureTrials = trials.filter(
          (info) => endDateWithGracePeriod(info) > now
        );
        const daysFromDateString = (dateString) =>
          Math.ceil(
            (new Date(dateString) - new Date()) / (1000 * 60 * 60 * 24)
          );

        const subscriptionData = (() => {
          if (timed.length === 0) {
            return {
              isSubscriber: false,
              isTrial: false,
              isExpired: false,
            };
          }

          if (activeOrFutureSubscriptions.length > 0) {
            addAttributeToUser(
              'subscription_start_date',
              activeOrFutureSubscriptions[0].startDate
            );
            return {
              isTrial: false,
              isSubscriber: true,
              isExpired: false,
              daysLeft: daysFromDateString(
                activeOrFutureSubscriptions[0].endDate
              ),
            };
          } else if (activeOrFutureTrials.length > 0) {
            addAttributeToUser(
              'trial_start_date',
              activeOrFutureTrials[0].startDate
            );
            return {
              isTrial: true,
              isSubscriber: false,
              isExpired: false,
              daysLeft: daysFromDateString(activeOrFutureTrials[0].endDate),
            };
          } else if (endedSubscriptions.length > 0) {
            return {
              isTrial: false,
              isSubscriber: true,
              isExpired: true,
            };
          } else if (endedTrials.length > 0) {
            return {
              isTrial: true,
              isSubscriber: false,
              isExpired: true,
            };
          }

          throw new Error(
            `Has timed access, but did not match any known types: ${JSON.stringify(
              timed
            )}`
          );
        })();

        const featureMode = (() => {
          if (subscriptionData.isTrial || subscriptionData.isSubscriber) {
            return subscriptionData.isExpired
              ? FEATURE_MODES.LITE
              : FEATURE_MODES.FULL;
          }

          return FEATURE_MODES.DEMO;
        })();

        return Promise.resolve({
          subscriptions: subscriptionData,
          featureMode: featureMode,
        });
      })
      .catch(() => {
        return fulfillWithValue({
          subscriptions: defaultSubscriptionOptions,
          featureMode: defaultFeatureMode,
        });
      });
  }
);

export const loginShaperHub = createAsyncThunk(
  'shaperhub/loginShaperHub',
  async (loginCredentials: { u: string; p: string }) => {
    const authURL = import.meta.env.VITE_SHAPER_URL_AUTH;
    return login(loginCredentials.u, loginCredentials.p, {
      authServiceUrl: authURL,
      tokenStorage: studioCookieStorage(window),
    }).catch((err) => {
      if (
        (localhostDomainRegex.test(window.location.host) ||
          window.location.host.includes('staging')) &&
        err.message.includes('Failed to fetch')
      ) {
        return Promise.reject(new Error(CustomErrors.WHITELIST_IP));
      }
      return Promise.reject(new Error(CustomErrors.UNABLE_LOGIN));
    });
  }
);

export const getLoginState = createAsyncThunk(
  'shaperhub/getLoginState',
  async () => {
    const token = await getShaperAccessToken();
    const tokenData = JSON.parse(atob(token.split('.')[1]));
    return tokenData.id;
  }
);

export const logoutShaperHub = createAsyncThunk(
  'shaperhub/logoutShaperHub',
  async () => {
    const authURL = import.meta.env.VITE_SHAPER_URL_AUTH;

    return logout({
      authServiceUrl: authURL,
      tokenStorage: studioCookieStorage(window),
    });
  }
);

export const getUser = createAsyncThunk('shaperhub/getUser', async () => {
  const apiURL = import.meta.env.VITE_SHAPER_URL_API;
  const basePath = ['users', 'me'];
  const pathStr = `/${basePath.join('/')}`;

  return shaperHubAxios
    .get(apiURL + pathStr)
    .then((response) => {
      if (response.data) {
        return response.data;
      }
      return { emailIsVerified: false, hasOriginAccess: false };
    })
    .catch((err) => {
      return Promise.reject();
    });
});

//Upload to shaperhub
//1. get usertree
//2. post blob to blob service
//3. check for filename conflict and rename if needed
//4. post file with filename with blob reference to home folder
const tryShaperHubUpdate = function ({
  apiURL,
  svgData,
  filenameIncrement = false,
  attempts = 100,
  documentName,
} = {}) {
  const blobUploadOptions = {
    headers: { 'content-type': 'application/octet-stream' },
  };

  const fileUploadOptions = {
    headers: { 'content-type': 'application/json' },
  };

  let userTree;

  //Get userspace tree and check for filename conflict
  return (
    shaperHubAxios
      .get(apiURL + '/files/userspace/tree/')
      .then((response) => {
        userTree = response.data;
        return Promise.resolve(true);
      })

      // Upload blob to blob service
      .then(() => {
        return shaperHubAxios.post(
          apiURL + '/blobs/',
          svgData,
          blobUploadOptions
        );
      })

      //Associate blob with file
      .then((blobSigs) => {
        const baseDocumentName =
          documentName === 'Untitled' ||
          documentName.replace(/\s/g, '').length === 0
            ? `Untitled__${blobSigs.data.blobs[0].substr(0, 6)}`
            : documentName;

        let iterations = 100;
        let trialFilename, filenameConflict;
        const nodeConflict = (node) => node.name === trialFilename;

        do {
          const filenameSuffix =
            iterations !== 100 ? `_(${100 - iterations})` : '';
          trialFilename = `${baseDocumentName}${filenameSuffix}.svg`;
          filenameConflict = userTree.find(nodeConflict) !== undefined;
          iterations--;
        } while (filenameConflict && iterations > 0);

        if (iterations === 0) {
          console.log(
            'Error - Duplicate file name. Please use a different filename'
          );
          return Promise.reject();
        }

        return shaperHubAxios.post(
          apiURL + '/files/userspace/tree/' + trialFilename,
          { type: 'file', blobs: blobSigs.data.blobs },
          fileUploadOptions
        );
      })
  );
};

export const uploadCanvasSvgShaperHub = createAsyncThunk(
  'shaperhub/uploadCanvasSvg',
  async (arg: { documentName: string }, { getState }) => {
    const documentName = arg.documentName.trim();
    const apiURL = import.meta.env.VITE_SHAPER_URL_API;
    const svgData = getCanvasSVG(getState().canvas.canvas);

    return tryShaperHubUpdate({ svgData, apiURL, documentName });
  }
);

export const readShaperHubFolder = createAsyncThunk(
  'shaperhub/readFolder',
  async (pathSeg: string | undefined, { getState }) => {
    const { currentFolder, currentPath, loggedIn } = getState().shaperHub;

    if (!loggedIn) {
      throw new Error('Cannot access ShaperHub before logging in');
    }

    let newPath;
    if (pathSeg === '..') {
      newPath = currentPath.slice(0, currentPath.length - 1);
    } else if (pathSeg === undefined || pathSeg === '') {
      newPath = currentPath;
    } else {
      //Does pathSeg exist in current folder, and if so, is it a folder?
      const validFolder =
        currentFolder.findIndex(
          (f) => f.name === pathSeg && f.type === 'folder'
        ) === -1
          ? false
          : true;
      if (validFolder) {
        newPath = [...currentPath, pathSeg];
      } else {
        throw new Error(
          `pathSeg ${pathSeg} is not a folder or is not found in the current folder at path ${currentPath}`
        );
      }
    }

    const basePath = ['files', 'userspace', 'tree'];
    const pathStr = `/${[...basePath, ...newPath].join('/')}/`;

    const apiURL = import.meta.env.VITE_SHAPER_URL_API;
    return shaperHubAxios.get(apiURL + pathStr).then((response) => {
      const folderContents = response.data;
      return Promise.resolve({
        currentFolder: folderContents,
        currentPath: newPath,
      });
    });
  }
);

export const updateShaperHubWorkspace = createAsyncThunk(
  'shaperhub/updateWorkspace',
  async (
    patch: { name: string; path: string; newName?: string; newPath?: string },
    { getState }
  ) => {
    const { loggedIn } = getState().shaperHub;

    if (!loggedIn) {
      throw new Error('Cannot access ShaperHub before logging in');
    }

    const basePath = ['files', 'userspace', 'tree'];
    const pathStr = `/${[...basePath].join('/')}/`;

    const apiURL = import.meta.env.VITE_SHAPER_URL_API;
    return shaperHubAxios
      .patch(apiURL + pathStr, patch)
      .then((response) => {
        return Promise.resolve(response.data);
      })
      .catch((err) => {
        if (err.response.status === 409) {
          return Promise.reject(new Error(CustomErrors.ALREADY_USED_RENAME));
        } else if (err.response.status === 400) {
          return Promise.reject(new Error(CustomErrors.BAD_CHARACTER_RENAME));
        }
        return Promise.reject(err);
      });
  }
);

export const getShaperHubFiles = createAsyncThunk(
  'shaperhub/recentFiles',
  async (limit: number | undefined, { getState }) => {
    const { loggedIn } = getState().shaperHub;
    if (!loggedIn) {
      throw new Error('Cannot access ShaperHub before logging in');
    }

    const apiURL = import.meta.env.VITE_SHAPER_URL_API;
    const basePath = ['files', 'userspace', 'search'];
    const pathStr = `/${basePath.join(
      '/'
    )}?sort=modified%3A-1&spaceType=userspace&type=external&limit=${
      limit ?? 50
    }`;
    return shaperHubAxios
      .get(apiURL + pathStr)
      .then((response) => {
        const files = response.data;
        return Promise.resolve(
          files.results as UserspaceExternalItemFileObject[]
        );
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }
);

export const getShaperHubFile = async function (file: UserspaceFileObject) {
  const pathStr = `/blobs/${file.blobs[0]}?filename=${file.name}`;
  const apiURL = import.meta.env.VITE_SHAPER_URL_API;
  const blobsResponse = await shaperHubAxios.get(apiURL + pathStr, {
    headers: {
      Accept: 'application/x-url',
    },
  });
  const url = blobsResponse.data;
  const dataResponse = await axios.get(url);
  return dataResponse.data;
};

export const getShaperHubExternalItem = createAsyncThunk(
  'shaperhub/getExternalItem',
  async (workspaceId: string, { getState }) => {
    const { loggedIn } = getState().shaperHub;

    if (!loggedIn) {
      throw new Error('Cannot access ShaperHub before logging in');
    }

    const apiURL = import.meta.env.VITE_SHAPER_URL_API;
    const basePath = ['files', 'externalItems', 'studio-workspace'];
    const pathStr = `/${basePath.join('/')}/${workspaceId}`;
    return shaperHubAxios
      .get(apiURL + pathStr)
      .then((response) => {
        const workspace = response.data;
        return Promise.resolve(workspace);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }
);

export const getShaperHubExternalItemSvg = function (
  file: UserspaceExternalItemFileObject
) {
  const workspaceId = file.externalItemId;
  const apiURL = import.meta.env.VITE_SHAPER_URL_API;
  const basePath = ['files', 'externalItems', 'studio-workspace'];
  const pathStr = `/${basePath.join('/')}/${workspaceId}/svg`;
  return shaperHubAxios
    .get(apiURL + pathStr, {
      timeout: apiTimeouts.shaperHub,
    })
    .then((response) => {
      const workspace = response.data;
      return Promise.resolve(workspace);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const getLocale = createAsyncThunk(
  'shaperhub/getLocale',
  async (args, { getState }) => {
    const state = getState();
    const featureFlags = selectFeatureFlags(state);
    const { localeURL } = getState().shaperHub;
    const devLocale = getDeveloperSettings('locale');

    if (
      featureFlags &&
      featureFlags['studio-developer-settings'] &&
      devLocale
    ) {
      return {
        ...defaultLocale,
        language: devLocale,
      };
    }

    try {
      return fetch(`${localeURL}/private/locale`, {
        method: 'GET',
        credentials: 'include',
      }).then((response) => response.json());
    } catch (ex) {
      return defaultLocale;
    }
  }
);

export const startTrial = createAsyncThunk(
  'shaperhub/startTrial',
  async (args, { getState }) => {
    const { loggedIn, paymentsURL, locale, userId, userIsVerified } =
      getState().shaperHub;

    if (!loggedIn && userIsVerified) {
      throw new Error(
        'Cannot start a trial before logging in and verify email'
      );
    }

    const chargebeesite = getChargebeeSiteForLocale(locale.displayLocale);
    const currency = locale.currency;

    const trialPath = `${paymentsURL}/providers/chargebee/config/trial/${chargebeesite}/shaper-studio/${currency}`;
    return shaperHubAxios
      .post(trialPath, { userId })
      .then((response) => {
        console.log(response);
        return Promise.resolve(true);
      })
      .catch((err) => {
        return Promise.resolve(false);
      });
  }
);

type DownloadBlobOptions = {
  blobId: string;
  skipImportScreen?: boolean;
};

export const downloadBlob = createAsyncThunk(
  'shaperhub/downloadBlob',
  async (
    { blobId, skipImportScreen = false }: DownloadBlobOptions,
    { getState }
  ) => {
    const { loggedIn } = getState().shaperHub;
    if (!loggedIn) {
      throw new Error('Cannot access ShaperHub before logging in');
    }

    const apiURL = import.meta.env.VITE_SHAPER_URL_API;
    const pathStr = `/blobs/${blobId}`;
    const blobsResponse = await shaperHubAxios.get(apiURL + pathStr, {
      headers: {
        Accept: 'application/x-url',
      },
    });
    const url = blobsResponse.data;
    const dataResponse = await axios.get(url);
    return dataResponse.data;
  }
);

export const getFirstWorkspace = createAsyncThunk(
  'shaperhub/getFirstWorkspace',
  async (arg, { getState }) => {
    const { loggedIn } = getState().shaperHub;
    if (!loggedIn) {
      throw new Error('Cannot access ShaperHub before logging in');
    }

    const apiURL = import.meta.env.VITE_SHAPER_URL_API;
    const pathStr = `/${['files', 'userspace', 'search'].join(
      '/'
    )}?sort=created%3A1&spaceType=userspace&type=external&limit=1`;

    return shaperHubAxios
      .get(apiURL + pathStr)
      .then((response) => {
        const results = response.data?.results[0] || null;
        return Promise.resolve(results);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }
);
