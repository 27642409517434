import React from 'react';
import { cancelEvent } from '@/Utility/events';

type Props = {
  children: React.ReactNode;
  className?: string;

  viewport: {
    x: number;
    y: number;
    width: number;
    height: number;
    size: {
      width: number;
      height: number;
    };
  };
};

export default function Viewport(props: Props) {
  const { viewport } = props;

  return (
    <svg
      data-viewport
      xmlns='http://www.w3.org/2000/svg'
      className={`ui-viewport ${props.className || ''}`}
      width={viewport.size.width}
      height={viewport.size.height}
      viewBox={`${viewport.x} ${viewport.y} ${viewport.width} ${viewport.height}`}
      style={{ display: 'block' }}
      onContextMenu={cancelEvent}
      data-cy='ui-viewport'
    >
      <g>{props.children}</g>
    </svg>
  );
}
