import React from 'react';
import { useAction } from '@/Actions/useAction';
import { useSelector } from 'react-redux';
import { isEqual } from '@/Utility/numeric';

// selectors
import {
  selectSelectionBounds,
  selectSelectedGroups,
} from '@/Redux/Slices/SelectionSlice';
import { selectOptions } from '@/Redux/Slices/SherpaContainerSlice';
import { selectFeatures } from '@/Redux/Slices/UISlice';

// actions
import UIFeatureAction from '@/Actions/UIFeature';
import UpdateWorkspaceOptionsAction from '@/Actions/UpdateWorkspaceOptions';

// components
import FloatingPanel from '@/Components/FloatingPanel/FloatingPanel';

// editors
import RotationEditor from './Rotation';
import SizeEditor from './Size';
import PositionEditor from './Position';
import RadiusEditor from './Radius';
import EllipseEditor from './Ellipse';
import PolygonEditor from './Polygon';
import TextEditor from './Text';
import AnchorSelectionEditor from './AnchorSelection';
import DistanceAdjustment from './Distance';
import PathType from './PathType';
import { entitlements } from '@/Helpers/Entitlements';
import { selectIsSelectionTwoAdjacentPoints } from '@/Redux/Slices/LineToolSlice';
import SelectionBox from '@/Helpers/SelectionBoxHelper';
import { Shape } from '@shapertools/sherpa-svg-generator/SvgGroup';

export default function EditGroupPropertiesPanel() {
  // selectors
  const selectedGroups = useSelector(selectSelectedGroups);
  const selectionBounds = useSelector(selectSelectionBounds) as SelectionBox;
  const { usePositioning } = useSelector(selectOptions);

  const hasPositioning = selectFeatures(entitlements.PRECISION_POSITION);
  const [selectedGroup] = selectedGroups;
  const tool = selectedGroup?.tool?.type;

  // computed
  const { length: selectionCount } = selectedGroups;
  const hasSelection = !!selectionCount;
  const isSingleSelection = selectionCount === 1;
  const isSameDimensions = isEqual(
    selectionBounds.width,
    selectionBounds.height,
    0.001
  );
  const isRectangle = isSingleSelection && tool === Shape.ROUNDED_RECT;
  const isEllipse = isSingleSelection && tool === Shape.CIRCLE;
  const isCircle = isEllipse && isSameDimensions;
  const isPoint = isSingleSelection && tool === Shape.POINT;
  const isPolygon = isSingleSelection && tool === Shape.POLYGON;
  const isText = isSingleSelection && tool === Shape.TEXT;
  const isAdjacentPoints = useSelector(selectIsSelectionTwoAdjacentPoints);
  const isIcon = isSingleSelection && tool === Shape.ICON;
  const noTool = isSingleSelection && tool === Shape.UNKNOWN;
  const lockAspectRatio = isPolygon || isText || isIcon || noTool || !tool;

  // actions
  const uiAction = useAction(UIFeatureAction);
  const updateWorkspaceOptionsAction = useAction(UpdateWorkspaceOptionsAction);

  // handle closing the menu
  function onClose() {
    updateWorkspaceOptionsAction.update();
    uiAction.toggleEditSelectionProperties(false);
  }

  // don't render without a selection
  if (!hasSelection) {
    return null;
  }

  return (
    <FloatingPanel
      edge='right'
      onClose={onClose}
      dataCy='edit-group-properties'
      className='group-properties-editor'
      title='Shape Properties'
      i18nTitleKey='shape-properties'
      hideHeadersOnDesktop
    >
      <PathType />
      {isText && <TextEditor />}
      {isCircle && <EllipseEditor />}
      {!isCircle && !isPoint && !isAdjacentPoints && (
        <SizeEditor
          lockAspectRatio={lockAspectRatio}
          selectedGroups={selectedGroups}
        />
      )}

      {isAdjacentPoints && <DistanceAdjustment points={selectedGroups} />}
      {!isCircle && !isPoint && <RotationEditor />}
      {isPolygon && <PolygonEditor />}
      {isRectangle && <RadiusEditor />}
      {hasPositioning && usePositioning && <PositionEditor />}
      {isSingleSelection && !isPoint && <AnchorSelectionEditor />}
    </FloatingPanel>
  );
}
