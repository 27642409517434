import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// display modes
import DefaultMode from '@/InteractionsManager/Modes/Default/DefaultMode';
import ShapeShifterMode from '@/InteractionsManager/Modes/ShapeShifter/ShapeShifterMode';
import AnchorSelectionMode from '@/InteractionsManager/Modes/AnchorSelection/AnchorSelectionMode';
import PlanMode from '@/InteractionsManager/Modes/Plan/PlanMode';
import ReviewMode from '@/InteractionsManager/Modes/Review/ReviewMode';

//TODO - make real import mode
import ImportMode from '@/InteractionsManager/Modes/Default/DefaultMode';

// actions
import { useAction } from '@/Actions/useAction';
import SetThemeAction from '@/Actions/SetTheme';
import DisabledMode from './Modes/Default/Interactions/Disabled';
import { selectIsEditMode } from '@/Redux/Slices/SyncSlice';

// each interaction manager
const MODES = {
  default: new DefaultMode(),
  'text-editor': new DefaultMode(),
  'shape-shifter': new ShapeShifterMode(),
  'anchor-selection': new AnchorSelectionMode(),
  import: new ImportMode(),
  plan: new PlanMode(),
  review: new ReviewMode(),
  disabled: new DisabledMode(),
};

export default function InteractionsModeManager(props) {
  const dispatch = useDispatch();
  const setThemeAction = useAction(SetThemeAction);

  const isViewOnly = !useSelector(selectIsEditMode);
  const ref = useRef();
  const [lastMode, setLastMode] = useState(null);
  const [gesture, setGesture] = useState(null);

  // handles checking for new
  useEffect(() => {
    let mode = MODES[props.mode];
    let modeName = props.mode;

    if (isViewOnly) {
      mode = MODES.disabled;
      modeName = 'disabled';
    }

    if (!mode) {
      console.error(`Missing mode: ${props.mode}`);
      return;
    }

    setThemeAction.set(mode.theme);

    if (lastMode !== modeName) {
      if (gesture) {
        gesture.destroy();
      }
      setGesture(mode.activate(dispatch, ref));
      setLastMode(modeName);
    }
  }, [props.mode, isViewOnly]);

  return (
    <div ref={ref} data-interaction-manager className='data-interaction'>
      {props.children}
    </div>
  );
}
