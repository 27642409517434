import { batch } from 'react-redux';
import { ALERT_TYPES } from '../defaults';
import {
  AlertActionPayload,
  actions as shaperHubActions,
} from '../Redux/Slices/ShaperHubSlice';
import BaseAction from './BaseAction';
import UpdateWorkspaceOptionsAction from './UpdateWorkspaceOptions';
import { toggleRename } from '@/Redux/Slices/UISlice';

export default class AlertAction extends BaseAction {
  clear() {
    this.dispatch(shaperHubActions.clearAlert());
  }

  set(alert: AlertActionPayload) {
    this.dispatch(shaperHubActions.setAlert(alert));
  }

  setDuplicateAlert() {
    const updateWorkspaceOptions = this.createAction(
      UpdateWorkspaceOptionsAction
    );
    batch(() => {
      this.set({
        msg: 'Design Duplicated',
        i18nKey: 'design-duplicated',
        type: ALERT_TYPES.DEFAULT_DISMISSIBLE,
      });
      updateWorkspaceOptions.update({ showApplicationMenu: true });
      this.dispatch(toggleRename(true));
    });
  }
}
